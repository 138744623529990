import React from 'react';

import styles from './AdContainer.module.css';
import Configuration from '../../lib/configuration';

const sizes = {
  homepage_middle_leaderboard: styles.homepageMiddleLeaderboard,
  home_partner_leaderboard: styles.homePartnerLeaderboard,
  home_branded_leaderboard: styles.homeBrandedLeaderboard,
  home_branded_tile: styles.homeBrandedTile,
  homepage_bottom: styles.homepageBottom,
  homepage_story_tile: styles.homepageStoryTile,
  search_header_standard: styles.headerLeaderboardStandard,
  search_header_transitional: styles.headerLeaderboardTransitional,
  search_results_leaderboard: styles.searchResultsLeaderboard,
  search_results_tile: styles.searchResultsTile,
  listing_header_standard: styles.headerLeaderboardStandard,
  listing_header_transitional: styles.headerLeaderboardTransitional,
  listing_detail_tile: styles.listingDetailTile,
  right_rail_tile: styles.rightRailTile,
  content_leaderboard: styles.contentLeaderboard,
  content_skyscraper: styles.contentSkyscraper,
};

const AdContainer = ({name, children}) => {
  const {size} = Configuration.get(`ads.${name}`) || {};
  const fullName = size ? `${name}_${size}` : name;
  const sizeClass = sizes[fullName] || '';
  return <div className={`${styles.root} ${sizeClass}`}>{children}</div>;
};
export default AdContainer;
