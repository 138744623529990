import React, {useEffect, useState} from 'react';

import {zeus} from '../../../lib/zeus';

const states = [
  {name: 'Alabama', abbreviation: 'AL'},
  {name: 'Alaska', abbreviation: 'AK'},
  {name: 'Arizona', abbreviation: 'AZ'},
  {name: 'Arkansas', abbreviation: 'AR'},
  {name: 'California', abbreviation: 'CA'},
  {name: 'Colorado', abbreviation: 'CO'},
  {name: 'Connecticut', abbreviation: 'CT'},
  {name: 'Delaware', abbreviation: 'DE'},
  {name: 'District of Columbia', abbreviation: 'DC'},
  {name: 'Florida', abbreviation: 'FL'},
  {name: 'Georgia', abbreviation: 'GA'},
  {name: 'Hawaii', abbreviation: 'HI'},
  {name: 'Idaho', abbreviation: 'ID'},
  {name: 'Illinois', abbreviation: 'IL'},
  {name: 'Indiana', abbreviation: 'IN'},
  {name: 'Iowa', abbreviation: 'IA'},
  {name: 'Kansas', abbreviation: 'KS'},
  {name: 'Kentucky', abbreviation: 'KY'},
  {name: 'Louisiana', abbreviation: 'LA'},
  {name: 'Maine', abbreviation: 'ME'},
  {name: 'Maryland', abbreviation: 'MD'},
  {name: 'Massachusetts', abbreviation: 'MA'},
  {name: 'Michigan', abbreviation: 'MI'},
  {name: 'Minnesota', abbreviation: 'MN'},
  {name: 'Mississippi', abbreviation: 'MS'},
  {name: 'Missouri', abbreviation: 'MO'},
  {name: 'Montana', abbreviation: 'MT'},
  {name: 'Nebraska', abbreviation: 'NE'},
  {name: 'Nevada', abbreviation: 'NV'},
  {name: 'New Hampshire', abbreviation: 'NH'},
  {name: 'New Jersey', abbreviation: 'NJ'},
  {name: 'New Mexico', abbreviation: 'NM'},
  {name: 'New York', abbreviation: 'NY'},
  {name: 'North Carolina', abbreviation: 'NC'},
  {name: 'North Dakota', abbreviation: 'ND'},
  {name: 'Ohio', abbreviation: 'OH'},
  {name: 'Oklahoma', abbreviation: 'OK'},
  {name: 'Oregon', abbreviation: 'OR'},
  {name: 'Pennsylvania', abbreviation: 'PA'},
  {name: 'Rhode Island', abbreviation: 'RI'},
  {name: 'South Carolina', abbreviation: 'SC'},
  {name: 'South Dakota', abbreviation: 'SD'},
  {name: 'Tennessee', abbreviation: 'TN'},
  {name: 'Texas', abbreviation: 'TX'},
  {name: 'Utah', abbreviation: 'UT'},
  {name: 'Vermont', abbreviation: 'VT'},
  {name: 'Virginia', abbreviation: 'VA'},
  {name: 'Washington', abbreviation: 'WA'},
  {name: 'West Virginia', abbreviation: 'WV'},
  {name: 'Wisconsin', abbreviation: 'WI'},
  {name: 'Wyoming', abbreviation: 'WY'},
];

const sortIgnoringThe = (p1, p2) => {
  const t1 = p1?.title?.replace(/^The /i, '');
  const t2 = p2?.title?.replace(/^The /i, '');
  if (t1 < t2) {
    return -1;
  }
  if (t1 > t2) {
    return 1;
  }
  return 0;
};

export const fetchPartners = async () => {
  let {partners} = await zeus('/api/partners');
  // https://github.com/airbnb/javascript/issues/684
  // eslint-disable-next-line react/no-did-mount-set-state
  const partnersByState = {};
  partners = partners.filter((p) => p.state);
  partners.forEach((partner) => {
    partnersByState[partner.state] = (partnersByState[partner.state] || []).concat(partner);
  });
  Object.keys(partnersByState).forEach((st) => {
    partnersByState[st].sort(sortIgnoringThe);
  });
  return {partners, partnersByState};
};

const AllPartnerSites = ({partnersByState}) => {
  const [openState, setOpenState] = useState('');

  return (
    <div className="AllPartnerStates">
      {states.map((st) => (
        <State
          key={st.abbreviation}
          state={st}
          partners={partnersByState[st.abbreviation]}
          openState={openState}
          onOpenState={setOpenState}
        />
      ))}
    </div>
  );
};

const State = ({openState, onOpenState, state, partners}) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    let cancelled = false;
    if (cancelled) {
      return;
    }
    if (open && openState !== state.abbreviation) {
      setOpen(false);
    }
    return () => (cancelled = true);
  }, [open, openState, state.abbreviation]);
  if (!partners || !partners.length) {
    return null;
  }
  return (
    <div className="PartnerState">
      <a
        className="PartnerStateName"
        href="#toggle"
        onClick={(e) => {
          e.preventDefault();
          if (!open) {
            onOpenState(state.abbreviation);
          }
          setOpen(!open);
        }}>
        {open ? '- ' : '+ '}
        {state.name} ({partners.length})
      </a>
      {open ? (
        <ul className="AllPartnerSites">
          {partners.map((i) => (
            <li key={i.host}>
              <a href={i.host}>
                {i.title}
                {i.city ? <> ({i.city})</> : null}
              </a>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

export default AllPartnerSites;
