import React from 'react';

import styles from './KotohomesLoansPage.module.css';
import Page from '../common/Page';
import KotohomesPageFooter from '../KotohomesPageFooter';
import calculatorImage from './calculator.png';
import Ad from '../Ad';

const KotohomesLoansPage = () => {
  return (
    <Page className={styles.root} title="Loans">
      <div className={styles.basePage}>
        <div className={styles.content}>
          <div className={styles.mortgage}>
            <img className={styles.image} src={calculatorImage} />
            <div className={styles.blurb}>Estimate monthly payments based on current rates.</div>
            <a className={styles.link} href="/calculator/mortgage">
              Mortgage
            </a>
          </div>
          <div className={styles.rentVsBuy}>
            <img className={styles.image} src={calculatorImage} />
            <div className={styles.blurb}>Should you rent or buy a home?</div>
            <a className={styles.link} href="/calculator/rent-vs-buy">
              Rent vs Buy
            </a>
          </div>
          <div className={styles.affordability}>
            <img className={styles.image} src={calculatorImage} />
            <div className={styles.blurb}>How much house can you afford?</div>
            <a className={styles.link} href="/calculator/affordability">
              Affordability
            </a>
          </div>
          <div className={styles.ad}>
            <Ad name="content_leaderboard" />
          </div>
        </div>
        <KotohomesPageFooter />
      </div>
    </Page>
  );
};

export default KotohomesLoansPage;
