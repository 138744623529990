import React from 'react';

import {getDisplayName} from './util';

const LISTENER_NAME = (() => {
  if (document.hidden !== undefined) {
    // Opera 12.10 and Firefox 18 and later support
    return 'visibilitychange';
  } else if (typeof document.mozHidden !== 'undefined') {
    return 'mozvisibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    return 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitvisibilitychange';
  }
})();

/**
 * High-level component that reports whether the document is visible.
 */
export function observeDocumentVisibility(WrappedComponent) {
  return class DocumentVisibilityObserver extends React.Component {
    static displayName = `DocumentVisibilityObserver(${getDisplayName(WrappedComponent)})`;

    state = {documentVisible: true};

    componentDidMount() {
      if (LISTENER_NAME) {
        document.addEventListener(LISTENER_NAME, this._update);
      }
      this._update();
    }

    componentWillUnmount() {
      if (LISTENER_NAME) {
        document.removeEventListener(LISTENER_NAME, this._update);
      }
    }

    render() {
      return <WrappedComponent documentVisible={this.state.documentVisible} {...this.props} />;
    }

    _update = () => {
      const documentVisible = !(
        document.hidden ||
        document.mozHidden ||
        document.msHidden ||
        document.webkitHidden
      );
      if (documentVisible !== this.state.documentVisible) {
        this.setState(() => ({documentVisible}));
      }
    };
  };
}
