import React from 'react';
import PropTypes from 'prop-types';

import styles from './CustomHeader.module.css';

const CustomHeader = ({src, height}) => {
  return (
    <header className={styles.root}>
      <iframe src={src} height={height} scrolling="no"></iframe>
    </header>
  );
};

CustomHeader.propTypes = {
  src: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
};

export default CustomHeader;
