import React from 'react';
import PropTypes from 'prop-types';

import {zeus} from '../../../lib/zeus';

export default class DynamicLinks extends React.Component {
  static propTypes = {
    source: PropTypes.string.isRequired,
    root: PropTypes.string.isRequired,
    className: PropTypes.string,
  };
  state = {links: []};

  componentDidMount() {
    this._loadContent();
  }

  componentDidUpdate(prevProps, _prevState, _snapshot) {
    if (prevProps.source != this.props.source) {
      this._loadContent();
    }
  }

  async _loadContent() {
    const resp = await zeus(this.props.source);
    const links = resp[this.props.root] || [];
    this.setState({links});
  }

  render() {
    return (
      <ul className={this.props.className}>
        {this.state.links.map((link) => (
          <li key={`${link.link}${link.title}`}>
            <a href={link.link}>{link.title}</a>
          </li>
        ))}
      </ul>
    );
  }
}
