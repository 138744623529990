import React from 'react';

import Configuration from '../../lib/configuration';
import KotohomesPageFooterV1 from './KotohomesPageFooterV1';

import styles from './KotohomesPageFooter.module.css';

const KotohomesPageFooter = () => {
  const baseDomainName = Configuration.get('base_domain_name');
  const year = new Date().getFullYear();
  return (
    <footer className={styles.root}>
      <div className={styles.links}>
        <a href={`https://${baseDomainName}`}>About</a>
        <a href="mailto:info@kotohomes.com">Contact Us</a>
        <a href={`https://${baseDomainName}/privacy-policy`}>Privacy</a>
      </div>
      <div className={styles.copyright}>&copy; {year} KotoHomes. All Right Reserved.</div>
    </footer>
  );
};

const KotohomesPageFooterWrapper = () => {
  const redesign = Configuration.get('homepage.redesign');
  return redesign ? <KotohomesPageFooter /> : <KotohomesPageFooterV1 />;
};

export default KotohomesPageFooterWrapper;
