import React from 'react';

import styles from './KotohomesAdvertisePage.module.css';
import image1 from './pexels-david-mcbee-1546168.jpg';
import image2 from './pexels-clay-elliot-5524166.jpg';
import Page from '../common/Page';
import KotohomesPageFooter from '../KotohomesPageFooter';

const KotohomesAdvertisePage = () => {
  return (
    <Page className={styles.root} title="Advertise">
      <div className={styles.basePage}>
        <div className={styles.content}>
          <div className={styles.blurb1}>
            Be a neighborhood expert Realtor or Lender for your hometown newspaper
          </div>
          <div className={styles.blurb2}>Limited and exclusive</div>
          <div className={styles.blurb3}>You, branded prominently in premium positions</div>
          <div className={styles.blurb4}>Receive buyer and seller leads for your neighborhood</div>
          <a className={styles.button} href="/advertise/contact">
            Get Started
          </a>
          <img className={styles.image1} src={image1} />
          <img className={styles.image2} src={image2} />
        </div>
      </div>
      <KotohomesPageFooter />
    </Page>
  );
};

export default KotohomesAdvertisePage;
