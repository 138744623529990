import React from 'react';
import PropTypes from 'prop-types';

import ScriptInjector from '../../../lib/support/scriptInjector';

const scriptInjector = new ScriptInjector(
  `https://ads.cordlessmedia.com/mortgage-center/calculators/rent-vs-buy/widget.js`
);

export class CMRentVsBuyCalculator extends React.Component {
  static propTypes = {
    address: PropTypes.string.isRequired,
    pid: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
    chartColor: PropTypes.arrayOf(PropTypes.string),
    linkColor: PropTypes.string,
  };

  async componentDidMount() {
    const {address, pid, zip, chartColor, linkColor} = this.props;
    await scriptInjector.load();
    window.cmwdgt.calcInit('message', {
      address,
      pid,
      zip,
      chartColor,
      linkColor,
    });
  }

  render() {
    return (
      <div>
        <div id="cmrbwidget" />
      </div>
    );
  }
}
