import moment from 'moment';
import L from 'leaflet';

import './models'; // Force self-registration
import Configuration from './configuration';

export default function startApplication({site}) {
  if (!site) {
    throw new Error('Cannot start application without a site');
  }

  Configuration.configure(site);

  L.Icon.Default.imagePath = '/images/leaflet/';

  moment.locale('en');
}
