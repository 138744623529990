import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {find} from 'underscore';
import {withRouter} from 'react-router';

import {getHeight, getWidth, ScheduledFunction} from '../../../lib/node_utils';
import {PriorityControls} from '../PriorityControls.react';
import {Spinner} from '../Spinner.react';
import Timer from '../../../lib/utils/timer';
import {ListingSearchResultSet} from '../../../lib/search/ListingQuery';
import {inGroupsOf} from '../../../lib/utils/arrays';
import ListingTile from '../listing_tile';
import Configuration from '../../../lib/configuration';
import Ad from '../Ad';
import Fixable from '../common/Fixable';
import Pagination from './Pagination';
import {urlForListing} from '../../../lib/support/routing';

const MAX_TILE_WIDTH = 300;
const MAX_TILES_PER_ROW = 3;
const TILE_MARGIN = 10;
const TABLET_SCREEN_WIDTH = 768;
const MOBILE_SCREEN_WIDTH = 480;

const TILE_HOVER_TIMER = new Timer();

function getTilesPerRow(width) {
  if (width >= TABLET_SCREEN_WIDTH) {
    return 3;
  } else if (width >= MOBILE_SCREEN_WIDTH) {
    return 2;
  } else {
    return 1;
  }
}

class EmptyTile {
  render() {
    return null;
  }

  elemAttributes() {
    return {};
  }
}

const empty = new EmptyTile();

class AdTile {
  render(/* context, row */) {
    return (
      <div>
        <Ad name="search_results_tile" />
      </div>
    );
  }

  elemAttributes() {
    return {
      className: 'ListingResultTile_type_ad',
    };
  }
}

class ItemTile {
  constructor(result, query) {
    this.result = result;
    this.query = query;
  }

  render(context, row) {
    const position = ++context.position;
    const {result} = this;
    return context.tileDecorator(
      <div className="ListingResultTile_type_item_t">
        <ListingTile
          key={result.listing.id}
          result={result}
          listing={result.listing}
          query={this.query}
          onClick={() => context.tileClickHandler(result, position, row, this)}
          onHover={() => context.tileHoverHandler(result)}
          stickySearch
          {...context.tileProps}
        />
      </div>,
      result.listing
    );
  }

  elemAttributes() {
    return {
      className: 'ListingResultTile_type_item',
      'data-listing-id': this.result.listing.id,
    };
  }
}

class CloseMatchDividerTile {
  constructor(spans, direction) {
    this.spans = spans;
    this.direction = direction;
  }

  render(context, _) {
    return (
      <div className="ListingResultList_close_match_divider" data-direction={this.direction}>
        <div className="ListingResultList_close_match_divider_content">
          <h3>
            {context.exactCount === 0
              ? 'There are no listings exactly matching your search.'
              : 'We ran out of exact matches.'}
          </h3>
          <h4>{'However, the following listings match your search closely.'}</h4>
        </div>
      </div>
    );
  }

  elemAttributes() {
    return {
      className: 'ListingResultTile_type_divider',
    };
  }
}

class TileRow {
  constructor(tiles, spans) {
    this.tiles = tiles;
    this.spans = spans;
  }

  render(context) {
    const cellStyle = {width: `${Math.floor(100 / this.spans)}%`};
    return (
      <ul>
        {this.tiles.map((tile, idx) => (
          <li key={idx} style={cellStyle} {...tile.elemAttributes()}>
            {tile.render(context)}
          </li>
        ))}
      </ul>
    );
  }
}

class CloseMatchDividerRow {
  render(context) {
    const {query, orderChangeHandler} = context;
    if (orderChangeHandler) {
      return (
        <Fixable mode="upscroll">
          <div className="ListingResultList_row_divider">
            <h3>{'Drag to Change Order of Matches'}</h3>
            <PriorityControls
              order={query.getOrder()}
              orderables={query.getOrderables()}
              onChange={orderChangeHandler}
            />
            <div className="ListingResultList_row_divider_lr">
              <span className="ListingResultList_row_divider_l">{'More important'}</span>
              <span className="ListingResultList_row_divider_r">{'Less important'}</span>
            </div>
          </div>
        </Fixable>
      );
    }
    return null;
  }
}

class AdRow {
  render(_) {
    return (
      <div className="ListingResultList_row_ad">
        <Ad name="search_results_leaderboard" />
      </div>
    );
  }
}

class ListingResultList extends React.Component {
  static getMaxWidth() {
    return TILE_MARGIN + MAX_TILES_PER_ROW * (MAX_TILE_WIDTH + TILE_MARGIN);
  }

  static propTypes = {
    resultSet: PropTypes.instanceOf(ListingSearchResultSet).isRequired,
    query: PropTypes.object.isRequired,
    showCloseMatchDivider: PropTypes.bool.isRequired,
    showExactnessLabel: PropTypes.bool.isRequired,
    showSponsoredLabel: PropTypes.bool.isRequired,
    showFeaturedLabel: PropTypes.bool.isRequired,
    showNewLabel: PropTypes.bool.isRequired,
    showPagination: PropTypes.bool.isRequired,
    showMapHint: PropTypes.bool.isRequired,
    onListingHover: PropTypes.func,
    onOrderChange: PropTypes.func,
    tilesPerRow: PropTypes.number,
    height: PropTypes.number,
    width: PropTypes.number,
    disableAds: PropTypes.bool,
    loading: PropTypes.bool,
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,

    // Optional function which is used to wrap each rendered listing tile.
    tileDecorator: PropTypes.func,

    // Props passed to <ListingTile> children.
    tileProps: PropTypes.object.isRequired,
  };

  static defaultProps = {
    tileDecorator: (child) => child,
    tileProps: Object.freeze({}),
    showCloseMatchDivider: true,
    showExactnessLabel: true,
    showSponsoredLabel: true,
    showFeaturedLabel: true,
    showNewLabel: true,
    showPagination: false,
    loading: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      rows: this._buildRows({}, this.props),
      tilesPerRow: this.props.tilesPerRow || 0,
      height: 0,
      resultCountAtThisHeight: 0,
      watermark: 0,
    };
  }

  UNSAFE_componentWillMount() {
    this._calculateTilesPerRow();
    this.setState({
      hoveringOverListing: null,
    });
  }

  componentDidMount() {
    this._node = null;
    this._mounted = true;
    this._updateDimensions();
  }

  componentWillUnmount() {
    this._mounted = false;
    this._node = null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.width !== this.props.width) {
      this._updateDimensions();
    } else if (
      prevProps.resultSet !== this.props.resultSet ||
      prevProps.resultSet.size !== this.props.resultSet.size ||
      this.state.height === 0
    ) {
      ScheduledFunction.wrap(this._updateDimensions).schedule(250);
    }

    const {onListingHover} = this.props;
    const {hoveringOverListing} = this.state;
    if (onListingHover && prevState.hoveringOverListing !== hoveringOverListing) {
      onListingHover(hoveringOverListing);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.tilesPerRow !== this.props.tilesPerRow) {
      this.setState({tilesPerRow: nextProps.tilesPerRow});
    }

    if (
      nextProps.resultSet !== this.props.resultSet ||
      nextProps.resultSet.size !== this.props.resultSet.size ||
      nextProps.showCloseMatchDivider !== this.props.showCloseMatchDivider ||
      nextProps.tilesPerRow !== this.props.tilesPerRow
    ) {
      this.setState({
        rows: this._buildRows(this.state, nextProps),
        hoveringOverListing: null,
      });
    }

    if (this.props.resultSet.size === 0) {
      this.setState({hoveringOverListing: null});
    }
  }

  render() {
    const {
      query,
      resultSet,
      showFeaturedLabel,
      showNewLabel,
      showSponsoredLabel,
      showPagination,
      showMapHint,
    } = this.props;
    const tilesPerRow = this.props.tilesPerRow || this.state.tilesPerRow || 3;
    const {rows} = this.state;
    const showExactnessLabel = this.props.showExactnessLabel && query.hasExactCriteria();

    if (!this.props.loading && resultSet.results.size === 0) {
      return (
        <div className="ListingResultList" ref={(node) => (this._node = node)}>
          <div className="ListingResultList_no_results">
            {'There are no matching listings, sorry.'}
          </div>
        </div>
      );
    }

    if (resultSet.size === 0 || !tilesPerRow) {
      return <div className="ListingResultList" ref={(node) => (this._node = node)} />;
    }

    const context = {
      query,
      tileProps: Object.assign(
        {
          showExactnessLabel,
          showSponsoredLabel,
          showFeaturedLabel,
          showNewLabel,
          showMapHint,
        },
        this.props.tileProps
      ),
      exactCount: resultSet.exactCount,
      tileDecorator: this.props.tileDecorator,
      tileClickHandler: this._handleTileClick,
      tileHoverHandler: this._handleTileHover,
      orderChangeHandler: this.props.onOrderChange,
      position: 0,
    };

    return (
      <div
        ref={(node) => (this._node = node)}
        className="ListingResultList"
        onMouseLeave={this._handleMouseLeave}>
        <div className="ListingResultList_rows">
          {rows.map((row, idx) => (
            <div key={idx} className="ListingResultList_row">
              {row.render(context)}
            </div>
          ))}
        </div>
        {showPagination && (
          <Pagination
            query={this.props.query}
            loading={this.props.loading}
            resultSet={this.props.resultSet}
          />
        )}
        {this.props.loading && (
          <div className="ListingResultList_loading_more">
            <Spinner title="Loading…" />
          </div>
        )}
      </div>
    );
  }

  _buildRows = (state, props) => {
    const {resultSet, query, disableAds} = props;
    if (!(resultSet && resultSet.size > 0)) {
      return [];
    }

    // TODO: When we have no initial tiles per row, the tiles per row is wrong,
    //   and while this will be corrected once we have the width and tile size,
    //   initially it probably causes scroll position to become wrong when
    //   navigating *back* to a result view
    const tilesPerRow = props.tilesPerRow || state.tilesPerRow || 3;

    // TODO: Use immutable.js all the way here
    const tiles = resultSet.results.toJS().map((result) => new ItemTile(result, query));

    let divisionRowIndex = -1;
    if (props.showCloseMatchDivider) {
      let divisionIndex = -1;
      find(tiles, (tile, i) => {
        if (tile.result.isFuzzyMatch()) {
          divisionIndex = i;
          return true;
        }
        return false;
      });
      if (~divisionIndex) {
        divisionRowIndex = Math.floor(divisionIndex / tilesPerRow);
        tiles.splice(
          divisionIndex,
          0,
          new CloseMatchDividerTile(
            tilesPerRow - (divisionIndex % tilesPerRow),
            divisionIndex % tilesPerRow < tilesPerRow - 1 ? 'right' : 'below'
          )
        );
      }
    }

    if (!disableAds) {
      const config = Configuration.get('ads.search_results_tile');
      if (config?.vendor) {
        const offset = config.offset * tilesPerRow - 1;
        const interval = config.interval * tilesPerRow - 1;
        for (let i = offset; i < tiles.length; i++) {
          tiles.splice(i, 0, new AdTile());
          i += interval;
        }
      }
    }

    let rows = inGroupsOf(tiles, tilesPerRow, {pad: true}).map(
      (groupedTiles) =>
        new TileRow(
          groupedTiles.map((t) => (t != null ? t : empty)),
          tilesPerRow
        )
    );

    if (query.hasExactCriteria() && query.canOrder()) {
      rows.splice(0, 0, new CloseMatchDividerRow());
    }

    if (!disableAds) {
      const config = Configuration.get('ads.search_results_leaderboard');
      if (config?.vendor) {
        const offset = config.offset;
        const interval = config.interval;
        for (let i = offset; i < rows.length; i++) {
          if (divisionRowIndex === -1 || i < divisionRowIndex || i > divisionRowIndex + 1) {
            rows.splice(i, 0, new AdRow());
            if (~divisionRowIndex && i < divisionRowIndex) {
              divisionRowIndex++;
            }
            i += interval;
          }
        }
      }
    }

    return rows;
  };

  _handleTileClick = (result, index, row) => {
    const {listing} = result;
    window.location.href = urlForListing(listing, this.props.location.search);
  };

  _handleMouseLeave = (/* event */) => {
    if (this.state.hoveringOverListing) {
      TILE_HOVER_TIMER.reset();
      this.setState({hoveringOverListing: null});
    }
  };

  _handleTileHover = (resultItem) => {
    const listing = resultItem.listing;
    if (listing) {
      TILE_HOVER_TIMER.fire(() => {
        if (this._mounted) {
          if (
            (this.state.hoveringOverListing && this.state.hoveringOverListing.id) ===
            (listing && listing.id)
          ) {
            return;
          }
          this.setState({hoveringOverListing: listing});
        }
      }, 500);
    }
  };

  // Calculate width and height of result element.
  _updateDimensions = () => {
    if (this._node == null) {
      if (this._mounted) {
        ScheduledFunction.wrap(this._updateDimensions).schedule(100);
      }
      return;
    }

    const height = getHeight(this._node);
    if (height !== this.state.height && height > 0) {
      this.setState({
        height,
        resultCountAtThisHeight: this.props.resultSet.size,
      });
    }

    this._calculateTilesPerRow();
  };

  _calculateTilesPerRow = () => {
    if (this._node == null || this.props.tilesPerRow) {
      return;
    }

    const width = this.props.width || getWidth(this._node);
    if (!width) {
      return;
    }

    const tilesPerRow = Math.min(MAX_TILES_PER_ROW, getTilesPerRow(width));
    if (tilesPerRow !== this.state.tilesPerRow) {
      this.setState({
        tilesPerRow,
        rows: this._buildRows(Object.assign({}, this.state, {tilesPerRow}), this.props),
      });
    }
  };
}

export default connect()(withRouter(ListingResultList));
