import {compact} from 'underscore';

import Configuration from '../configuration';

// https://developers.google.com/analytics/devguides/migration/ua/analyticsjs-to-gtagjs#analyticsjs_2_gtagjs
// https://developers.google.com/analytics/devguides/collection/gtagjs

const setup = (tagIds) => {
  window.dataLayer = window.dataLayer || [];
  const gtag = function () {
    window.dataLayer.push(arguments);
  };
  window.gtag = gtag;

  const additionalConfigInfo = {
    custom_map: {
      dimension1: 'kh_agent_name',
      dimension2: 'kh_broker_name',
      dimension3: 'kh_address',
      dimension5: 'kh_mls_number',
      dimension7: 'kh_site_type',
      dimension4: 'kh_site_name',
      dimension6: 'kh_organization',
    },
    kh_env: Configuration.get('deploy_environment'),
    kh_site_type: Configuration.get('type'),
    kh_site_name: Configuration.get('name'),
    kh_organization: Configuration.get('org'),
  };
  // This setup code executes before the page context is fetched so do
  // our best to inject extra page level values here. The gtag documentation
  // claims we can use set to override them later but in practice this doesn't
  // work, so they are set just once and won't change until the next full
  // page load.
  // https://developers.google.com/tag-platform/gtagjs/reference#set
  // https://stackoverflow.com/questions/65935187/gtag-set-is-not-attaching-data-as-expected
  const search = window.location.search;
  if (search) {
    Object.assign(additionalConfigInfo, searchAnalyticsParams(search));
  }

  gtag('js', new Date());
  tagIds.forEach((tagId) => {
    gtag('config', tagId, additionalConfigInfo);
  });

  const tagId = tagIds[0];
  const script = document.createElement('script');
  script.src = `https://www.googletagmanager.com/gtag/js?id=${encodeURIComponent(tagId)}`;
  document.head.appendChild(script);
};

export const searchAnalyticsParams = (search) => {
  const searchParams = new URLSearchParams(search);
  return {
    kh_search_type: searchParams.get('type') || 'for_sale',
    kh_search_territory: searchParams.get('territory'),
    kh_search_zipcode: searchParams.get('zip_code'),
  };
};

export const legacyVirtualPageViews = (history) => {
  const trackerIds = Configuration.get('analytics.google.tracker_ids');
  if (trackerIds == null || trackerIds.length === 0) {
    return null;
  }
  const legacyTrackerIds = trackerIds.filter((id) => id.startsWith('UA-'));
  if (legacyTrackerIds.length === 0) {
    return;
  }
  console.log(
    'Universal Analytics detected, registering for history changes to trigger page views',
    legacyTrackerIds
  );
  history.listen((location) => {
    window.gtag('event', 'page_view', {send_to: legacyTrackerIds});
  });
};

export default class GoogleAnalyticsAdapter {
  static configure() {
    const trackerIds = Configuration.get('analytics.google.tracker_ids');
    if (trackerIds == null || trackerIds.length === 0) {
      return null;
    }
    setup(trackerIds);
    return new GoogleAnalyticsAdapter();
  }

  async handleAnalyticsEvent(name, data) {
    const gtag = window.gtag;
    if (!gtag) {
      return;
    }
    switch (name) {
      case 'clickedAgentClickToCallButton':
      case 'clickedAgentShowEmail':
      case 'submittedAgentInfoRequestEmail':
        gtag('event', name, {
          event_category: 'Hard Lead',
          kh_agent_name: data.agent.name,
          kh_broker_name: data.agent?.broker?.name || data.listing?.broker?.name,
          kh_address: address(data.listing),
          kh_mls_number: data.listing?.mls_number,
        });
        break;

      case 'clickedBrokerClickToCallButton':
      case 'clickedBrokerShowEmail':
      case 'submittedBrokerInfoRequestEmail':
        gtag('event', name, {
          event_category: 'Hard Lead',
          kh_agent_name: data.listing?.agent?.name,
          kh_broker_name: data.broker.name,
          kh_address: address(data.listing),
          kh_mls_number: data.listing?.mls_number,
        });
        break;

      case 'clickedListingDirections':
      case 'sharedListingByEmail': {
        const {listing} = data;
        gtag('event', name, {
          event_category: 'Soft Lead',
          kh_agent_name: listing.agent?.name,
          kh_broker_name: listing.broker?.name,
          kh_address: address(listing),
          kh_mls_number: listing.mls_number,
          kh_listing_type: listing.type,
          kh_listing_zipcode: listing.zip_code,
          kh_listing_id: listing.id,
        });
        break;
      }

      case 'viewedListing':
        const {listing} = data;
        gtag('event', name, {
          kh_agent_name: listing.agent?.name,
          kh_broker_name: listing.broker?.name,
          kh_address: address(listing),
          kh_mls_number: listing.mls_number,
          kh_listing_type: listing.type,
          kh_listing_zipcode: listing.zip_code,
          kh_listing_id: listing.id,
        });
        break;

      case 'search_change':
      case 'lead_form_view':
      case 'lead_form_submit':
      case 'lead_form_call':
      case 'lead_form_cancel':
        gtag('event', name, data);
        break;
    }
  }
}

function address(listing) {
  if (!listing) {
    return null;
  }
  return compact([listing.street_address, listing.city, listing.state]).join(', ');
}
