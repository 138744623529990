import React, {useState, useEffect} from 'react';
import {Provider} from 'react-redux';

import {zeus} from './lib/zeus';
import startApplication from './lib/startApplication';
import configureStore from './lib/configureStore';
import Platform from './lib/platform';
import Root from './app/components/Root';
import {setupAds} from './lib/ads';

function App() {
  const [site, setSite] = useState();
  const [store, setStore] = useState();

  useEffect(() => {
    (async () => {
      try {
        const s = await zeus(`/api/sites/me`);
        Platform.setSiteTestMode(s.test_mode);
        setSite(s);
      } catch (e) {
        setSite({});
        console.error(e);
      }
    })();
  }, []);

  useEffect(() => {
    if (!site) {
      return;
    }
    startApplication({site});
    const s = configureStore({});
    setStore(s);
    setupAds();
  }, [site]);

  if (!store) {
    return null;
  }
  return (
    <Provider store={store}>
      <Root />
    </Provider>
  );
}

export default App;
