import React from 'react';

import Configuration from '../../lib/configuration';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this._html = Configuration.get('branding.footer.html');
  }

  render() {
    if (!this._html) {
      return null;
    }

    return (
      <div className="Footer">
        <span className="Footer_branding" dangerouslySetInnerHTML={{__html: this._html}} />
      </div>
    );
  }
}

export default Footer;
