import styles from './placeholder.module.css';

const sizes = {
  homepage_middle_leaderboard: styles.homepageMiddleLeaderboard,
  home_partner_leaderboard: styles.homePartnerLeaderboard,
  home_branded_leaderboard: styles.homeBrandedLeaderboard,
  home_branded_tile: styles.homeBrandedTile,
  homepage_bottom: styles.homepageBottom,
  homepage_story_tile: styles.tile,
  search_header_standard: styles.headerLeaderboardStandard,
  search_header_transitional: styles.headerLeaderboardTransitional,
  search_results_leaderboard: styles.searchResultsLeaderboard,
  search_results_tile: styles.tile,
  listing_header_standard: styles.headerLeaderboardStandard,
  listing_header_transitional: styles.headerLeaderboardTransitional,
  listing_detail_tile: styles.tile,
  right_rail_tile: styles.rightRailTile,
  content_leaderboard: styles.contentLeaderboard,
  content_skyscraper: styles.contentSkyscraper,
};

const create = (domId, adName, adNameOverride, targeting) => {
  const elem = document.getElementById(domId);
  if (!elem) {
    console.error(`unable to find element for placeholder ad ${adName} at ${domId}`);
  }

  // <div class="container">
  //   <div class="placeholder">
  //     <div class="name">{name}</div>
  //     <div class="targeting">{targeting}</div>
  //   </div>
  // </div>

  const elName = document.createElement('div');
  elName.classList.add(styles.name);
  elName.textContent = adName;

  const elTargeting = document.createElement('div');
  elTargeting.classList.add(styles.targeting);
  elTargeting.textContent = JSON.stringify(targeting, null, '  ');

  const elPlaceholder = document.createElement('div');
  elPlaceholder.classList.add(styles.placeholder);
  const sizeClass = sizes[adName];
  if (sizeClass) {
    elPlaceholder.classList.add(sizeClass);
  }
  elPlaceholder.appendChild(elName);
  elPlaceholder.appendChild(elTargeting);

  const elContainer = document.createElement('div');
  elContainer.classList.add(styles.container);
  elContainer.appendChild(elPlaceholder);

  elem.appendChild(elContainer);

  return () => {
    elem.removeChild(elContainer);
  };
};

const vendor = {create};
export default vendor;
