import React from 'react';
import PropTypes from 'prop-types';

import ScriptInjector from '../../../lib/support/scriptInjector';

const scriptInjector = new ScriptInjector(
  'https://ads.cordlessmedia.com/mortgage-center/calculators/afford/widget.js'
);

export class CMAffordabilityCalculator extends React.Component {
  static propTypes = {
    address: PropTypes.string.isRequired,
    pid: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
    apt: PropTypes.string,
    price: PropTypes.string,
    downPayment: PropTypes.string,
    interestRate: PropTypes.string,
    hoa: PropTypes.string,
    homeIns: PropTypes.string,
    propTax: PropTypes.string,
    chartColor: PropTypes.arrayOf(PropTypes.string),
    linkColor: PropTypes.string,
  };

  async componentDidMount() {
    const {
      address,
      pid,
      zip,
      apt,
      price,
      downPayment,
      interestRate,
      hoa,
      homeIns,
      propTax,
      chartColor,
      linkColor,
    } = this.props;
    await scriptInjector.load();
    window.cmwdgt.affCalcInit('message', {
      address,
      pid,
      zip,
      apt,
      price,
      downPayment,
      interestRate,
      hoa,
      homeIns,
      propTax,
      chartColor,
      linkColor,
    });
  }

  render() {
    return (
      <div>
        <div id="cmawidget" />
      </div>
    );
  }
}
