import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import {Listing} from '../../../lib/models';
import Page from '../common/Page';
import ListingDetail from '../listings/ListingDetail';
import ErrorPage from '../ErrorPage';
import {analyticsEvent} from '../../../lib/actions';
import Ad from '../Ad';

const ListingPage = ({dispatch, listing: listingData}) => {
  const [listing] = useState(listingData ? new Listing(listingData) : null);
  useEffect(() => {
    if (listing) {
      dispatch(analyticsEvent('viewedListing', {listing}));
    }
  }, [dispatch, listing]);
  if (!listing) {
    return (
      <ErrorPage
        title="Listing not found"
        message="This listing may have been deleted."
        returnLink
      />
    );
  }
  if (!listing) {
    return (
      <ErrorPage
        title="Listing not found"
        message="This listing may have been deleted."
        returnLink
      />
    );
  }
  return (
    <Page
      title={listing.formatTitle()}
      showBacklink
      topAdSlot={
        <div className="ListingPage_ad">
          <Ad name="listing_header" />
        </div>
      }>
      <ListingDetail listing={listing} agent={listing.agent} broker={listing.broker} />
    </Page>
  );
};

export default connect()(ListingPage);
