import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {isEqual} from 'underscore';

import {ScheduledFunction} from '../../../lib/node_utils';
import {getDisplayName} from './util';

const SCROLL_DELAY = 1000 / 3;

export const ScrollPositionShape = PropTypes.shape({
  left: PropTypes.number.isRequired,
  top: PropTypes.number.isRequired,
});

/**
 * High-level component that reports the current window scroll position to a wrapped
 * component. All props are passed through. The scroll position is passed
 * as the prop `scrollPosition`.
 */
export function observeScrollPosition(WrappedComponent) {
  return class Observer extends Component {
    static displayName = `ScrollPositionObserver(${getDisplayName(WrappedComponent)})`;

    state = getScrollPosition();

    constructor(...args) {
      super(...args);
      this._updater = ScheduledFunction.wrap(this._update);
    }

    componentDidMount() {
      this._mounted = true;
      this._updater.schedule(0);
      window.addEventListener('scroll', this._scheduleUpdate);
    }

    componentWillUnmount() {
      this._mounted = false;
      window.removeEventListener('scroll', this._scheduleUpdate);
      this._updater.stop();
    }

    render() {
      return <WrappedComponent {...this.props} {...this.state} />;
    }

    _scheduleUpdate = () => {
      this._updater.schedule(SCROLL_DELAY);
    };

    _update = () => {
      if (this._mounted) {
        const newState = getScrollPosition();
        if (!isEqual(newState, this.state)) {
          this.setState(newState);
        }
      }
    };
  };
}

function getScrollPosition() {
  return {
    scrollPosition: {
      left: window.pageXOffset || window.scrollX || window.scrollLeft || 0,
      top: window.pageYOffset || window.scrollY || window.scrollTop || 0,
    },
  };
}
