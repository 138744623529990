import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {compact} from 'underscore';
import {List, Range} from 'immutable';

import {MouseEntryObserver, ScheduledFunction} from '../../lib/node_utils';
import {
  observeComponentVisibility,
  componentVisiblityMakeOnComponentDidUpdate,
} from '../components/wrappers/componentVisibilityObserver';
import {Listing} from '../../lib/models';
import {inGroupsOf} from '../../lib/utils/arrays';
import ListingTile from '../components/listing_tile';
import Sizer from '../components/common/Sizer';
import {parseGeoJSONPolygon} from '../../lib/utils/geo';
import {urlForListing} from '../../lib/support/routing';
import {
  PropTypeColor,
  PropTypeDimension,
  validColor,
  validDimension,
  style,
  styleIf,
} from './cssOverrides';
import {zeus} from '../../lib/zeus';

const REFRESH_INTERVAL_SECONDS = 30;
const CACHE_MAX_AGE_SECONDS = 600;
const MAX_RESULTS_SIZE = 100;
const DEFAULT_RESULTS_SIZE = 1;
const MIN_TILE_WIDTH = 160;
const MIN_LANDSCAPE_WIDTH = 500;

const chunk = (list, size = 1) =>
  Range(0, list.count(), size)
    .map((start) => list.slice(start, start + size).toList())
    .toList();

class ListingsWidget extends React.Component {
  static propTypes = {
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    result_limit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    new_listings_only: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    mls_number: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    ]),
    bounding_area: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    tiles_per_row: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    agent_uid: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    broker_uid: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    type: PropTypes.oneOf(Listing.TYPES),
    property_type: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    zip_codes: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    open_house_only: PropTypes.bool,

    query: PropTypes.shape({
      filter: PropTypes.object,
      rank: PropTypes.object,
      sort: PropTypes.object,
    }),

    // Style customizations
    background_color: PropTypeColor,
    widget_border_color: PropTypeColor,
    widget_border_width: PropTypeDimension,
    widget_border_radius: PropTypeDimension,
    widget_padding: PropTypeDimension,

    componentVisible: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    result_limit: DEFAULT_RESULTS_SIZE,
    tiles_per_row: 3,
  };

  state = {
    listings: new List(),
    hasMouse: false,
  };

  _isUnmounted = false;

  constructor(props) {
    super(props);
    this._shuffler = ScheduledFunction.wrap(this._shuffle);
    this._reported = {};
    this._prefetchedResults = new List();
    this._onComponentDidUpdate = componentVisiblityMakeOnComponentDidUpdate.bind(this)({
      onVisible: () => {
        this._setShuffle(true);
      },
      onNotVisible: () => this._setShuffle(false),
    });
    this._mouseEntryObserver = new MouseEntryObserver((hasMouse) => this.setState({hasMouse}));
  }

  UNSAFE_componentWillMount() {
    this._refresh();
  }

  componentDidMount() {
    this._setShuffle(this.props.componentVisible);
  }

  componentWillUnmount() {
    this._isUnmounted = true;
    this._mouseEntryObserver.unobserve();
  }

  async UNSAFE_componentWillUpdate(nextProps) {
    const {result_limit} = this.props;
    if (nextProps.result_limit !== result_limit) {
      await this._refresh();
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    this._onComponentDidUpdate(prevProps);
  }

  _handleListingClick = (listing /*, data */) => {
    const targetUrl = urlForListing(listing);
    window.open(targetUrl, '_top');
  };

  render() {
    if (this.state.loading) {
      return null;
    }

    const StyleOverrides = () => (
      <style>
        {`
        .listings_widget {
          ${style('background-color', validColor(this.props.background_color))}
          ${styleIf(
            this.props.widget_border_color || this.props.widget_border_width,
            'border-style',
            'solid'
          )}
          ${style('border-color', validColor(this.props.widget_border_color))}
          ${style('border-radius', validDimension(this.props.widget_border_radius))}
          ${style('border-width', validDimension(this.props.widget_border_width))}
          ${style('padding', validDimension(this.props.widget_padding))}
        }

      `}
      </style>
    );

    const {listings} = this.state;
    if (listings.size === 0) {
      return (
        <div className="listings_widget">
          <StyleOverrides />
          <div className="listing_widget_no_result">
            {isTrueProp(this.props.open_house_only)
              ? 'We have no open houses scheduled at the moment – please check back soon.'
              : "We don't have any listings to display at the moment."}
          </div>
        </div>
      );
    }

    return (
      <Sizer
        child={({width}) => (
          <div
            className="listings_widget"
            data-style={width < MIN_LANDSCAPE_WIDTH ? 'portrait' : 'landscape'}
            ref={(e) => this._mouseEntryObserver.observe(e)}>
            <StyleOverrides />
            <div className="listings_widget_main">
              <div className="listings_widget_main_listings" ref={(e) => (this._node = e)}>
                {this._buildTileRows(width, listings.toJS(), (row, rowIdx) => (
                  <ul key={rowIdx}>
                    {row.map((listing, cellIdx) => (
                      <li
                        key={cellIdx}
                        style={{
                          width: `${100 / row.length}%`,
                        }}>
                        {listing && (
                          <ListingTile listing={listing} onClick={this._handleListingClick} />
                        )}
                      </li>
                    ))}
                  </ul>
                ))}
              </div>
            </div>
          </div>
        )}
      />
    );
  }

  _buildTileRows(width, listings, fn) {
    if (width === 0) {
      return [];
    }

    const {tiles_per_row} = this.props;
    let tilesPerRow = Math.min(listings.length, Math.max(1, +tiles_per_row));
    if (tilesPerRow === 0) {
      return [];
    }
    if (width / tilesPerRow < MIN_TILE_WIDTH) {
      tilesPerRow = Math.max(1, Math.floor(width / MIN_TILE_WIDTH));
    }

    return (
      inGroupsOf(listings, tilesPerRow, {pad: false})
        // FIXME: Hack to fix padding in inGroupsOf():
        .filter((row) => compact(row).length === tilesPerRow)
        .map((row, rowIdx) => fn(row, rowIdx))
    );
  }

  async _refresh() {
    if (this._isUnmounted) {
      return;
    }
    this.setState({loading: true});

    if (this._prefetchedResults.size == 0) {
      const q = {
        filter: {
          location: {},
        },
        sort: {},
        ...this.props.query,
      };

      const polygon = parseGeoJSONPolygon(this.props.bounding_area);
      if (polygon) {
        q.filter.location.area = polygon.map(([lat, lng]) => ({lat, lng}));
      } else if (this.props.bounding_area) {
        console.error('Boundary area is not a valid GeoJSON polygon:', this.props.bounding_area);
      }

      const mlsNumber = this.props.mls_number;
      if (mlsNumber) {
        q.filter.listingId = ensureArray(mlsNumber).map((s) => String(s).toLowerCase().trim());
      }

      if (this.props.status) {
        q.filter.status = this.props.status;
      }

      const agentId = this.props.agent_uid;
      if (agentId) {
        q.filter.agent = {ids: ensureArray(agentId)};
      }

      const brokerId = this.props.broker_uid;
      if (brokerId) {
        q.filter.broker = {ids: ensureArray(brokerId)};
      }

      const zips = this.props.zip_codes;
      if (zips) {
        q.filter.location.zipCode = ensureArray(zips);
      }

      if (isTrueProp(this.props.new_listings_only)) {
        q.filter.publishTime = {
          min: moment().startOf('day').subtract(14, 'days').format('YYYY-MM-DD[T]HH:mm:ssZ'),
        };
      }

      if (this.props.type) {
        q.filter.type = this.props.type;
      }
      q.filter.propertyType = ensureArray(this.props.property_type);

      const resultSetSize = Math.min(MAX_RESULTS_SIZE, Math.max(1, this.props.result_limit));

      const prefetchedResultSetsNumber = Math.min(
        parseInt(MAX_RESULTS_SIZE / resultSetSize),
        parseInt(CACHE_MAX_AGE_SECONDS / REFRESH_INTERVAL_SECONDS) + 1
      );

      q.limit = prefetchedResultSetsNumber * resultSetSize;
      const data = await zeus('/api/query/listings', {
        queryObj: q,
      });
      const allListings = new List(data.listings.results).map((r) => new Listing(r.listing));
      this._prefetchedResults = chunk(allListings, resultSetSize);
    }

    const listings = this._prefetchedResults.first() || new List();
    this._prefetchedResults = this._prefetchedResults.shift();

    this._reported = {};
    this.setState({listings, loading: false});
  }

  async _setShuffle(enable) {
    if (enable) {
      if (!this.hasSearched) {
        await this._refresh();
        this.hasSearched = true;
      }
      this._shuffler.scheduleEvery(REFRESH_INTERVAL_SECONDS * 1000);
    } else {
      this._shuffler.stop();
    }
  }

  _shuffle = () => {
    if (!this.state.hasMouse) {
      this._refresh();
    }
  };
}

export default observeComponentVisibility(ListingsWidget);

function ensureArray(v) {
  if (v != null && !Array.isArray(v)) {
    return [v];
  }
  return v;
}

function isTrueProp(v) {
  return v === true || v === 'true';
}
