import Configuration from '../../lib/configuration';
import cordless from './cordless';
import google from './google';
import placeholder from './placeholder';
import {setupAdSense} from './ad_sense';

export const setupAds = () => {
  const adsConfig = Configuration.get('ads');
  if (!adsConfig) {
    return;
  }
  const {ad_sense_id: adSenseId} = adsConfig;
  if (adSenseId) {
    setupAdSense(adSenseId);
  }
  const configuredVendors = {};
  for (const name in adsConfig) {
    const config = adsConfig[name];
    if (config.vendor) {
      configuredVendors[config.vendor] = true;
    }
  }
  if (configuredVendors.google) {
    google.setup();
  }
  if (configuredVendors.cordless) {
    cordless.setup();
  }
};

const vendors = {
  cordless,
  google,
  placeholder,
};

export const createAd = (domId, vendorName, adName, adNameOverride, targeting) => {
  if (!vendorName) {
    return;
  }
  const vendor = vendors[vendorName];
  if (!vendor) {
    console.error(`unsupported ${vendorName} ad ${adName}`);
    return;
  }
  let cleanup;
  try {
    cleanup = vendor.create(domId, adName, adNameOverride, targeting);
  } catch (e) {
    console.error(`error creating ${vendorName} ad ${adName}`, e);
    return;
  }
  if (!cleanup) {
    return;
  }
  if (typeof cleanup !== 'function') {
    console.error(
      `expected falsy or function value when creating ${vendorName} ad ${adName}`,
      cleanup
    );
    return;
  }
  return () => {
    try {
      cleanup();
    } catch (e) {
      console.error(`error removing ${vendorName} ad ${adName}`, e);
    }
  };
};
