import React from 'react';
import PropTypes from 'prop-types';

import logo from '../../images/kotohomes-logo.png';

const KotohomesHeaderV1 = ({title}) => {
  return (
    <header className="KotohomesHeader">
      <div className="KotohomesHeader_content">
        <div className="KotohomesHeader_container">
          <a className="KotohomesHeader_logo" href="/">
            <img src={logo} alt="Kotohomes" />
          </a>
          <div className="KotohomesHeader_partnered_with">
            {'Partnered with: '}
            <div className="KotohomesHeader_partner">{title}</div>
          </div>

          <div className="KotohomesHeader_links">
            <a href="/">Home</a>
            <a href="/calculator/mortgage">
              Mortgage<span className="LongDesc"> Calculator</span>
            </a>
            <a href="/calculator/affordability">
              Affordability<span className="LongDesc"> Calculator</span>
            </a>
            <a href="/calculator/rent-vs-buy">
              Rent vs Buy<span className="LongDesc"> Calculator</span>
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

KotohomesHeaderV1.propTypes = {
  title: PropTypes.string.isRequired,
};

export default KotohomesHeaderV1;
