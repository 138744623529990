import React from 'react';
import PropTypes from 'prop-types';

import DynamicLinks from './common/DynamicLinks';
import Configuration from '../../lib/configuration';
import logo from '../../images/kotohomes-logo.png';

const KotohomesPageFooter = ({includePartnerLinks = false}) => {
  const baseDomainName = Configuration.get('base_domain_name');
  const customerHasFootlines = Configuration.get('homepage.customer_has_footlines');
  const siteChecksum = Configuration.getSiteChecksum();
  return (
    <footer className="KotohomesPageFooterV1">
      {customerHasFootlines && includePartnerLinks && (
        <div className="Partner">
          <DynamicLinks
            className="Links"
            source={`/api/footlines?c=${encodeURIComponent(siteChecksum)}`}
            root="footlines"
          />
        </div>
      )}
      <div className="Kotohomes">
        <ul className="Links">
          <li>
            <a href={`https://${baseDomainName}`}>About Us</a>
          </li>
          <li>
            <a href="mailto:info@kotohomes.com">Contact Us</a>
          </li>
          <li>
            <a href={`https://${baseDomainName}/privacy-policy`}>Privacy Policy</a>
          </li>
        </ul>
        <img className="Logo" src={logo} alt="Kotohomes" width={220} />
      </div>
      <div className="Copyright">&copy; 2020 Koto Homes. All Right Reserved.</div>
    </footer>
  );
};

KotohomesPageFooter.propTypes = {
  includePartnerLinks: PropTypes.bool,
};

export default KotohomesPageFooter;
