import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Router, Route, browserHistory} from 'react-router';
import {syncHistoryWithStore} from 'react-router-redux';

import {
  KotohomesHomePageV1,
  SearchPage,
  SearchSplatPage,
  ListingPage,
  AgentPage,
  BrokerPage,
  KotohomesArticlePage,
  KotohomesListiclePage,
  KotohomesCalculatorPage,
} from './pages';
import ErrorPage from './ErrorPage';
import Configuration from '../../lib/configuration';
import {PageContext, PageContextFromLocation} from '../../lib/pageContext';
import KotohomesHomePage from './pages/KotohomesHomePage';
import KotohomesLoansPage from './pages/KotohomesLoansPage';
import KotohomesAdvertisePage from './pages/KotohomesAdvertisePage';
import KotohomesAdvertiseContactPage from './pages/KotohomesAdvertiseContactPage';

const NotFound = () => {
  return (
    <ErrorPage
      title="Not found"
      message="We apologize, but this is not a valid location."
      returnLink
    />
  );
};

class Root extends React.Component {
  static contextTypes = {
    store: PropTypes.object,
  };

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  UNSAFE_componentWillMount() {
    this._history = syncHistoryWithStore(browserHistory, this.context.store);
  }

  render() {
    if (!Configuration.get('name')) {
      return (
        <ErrorPage
          title="Site Not found"
          message="There is currently no site available under this domain name."
        />
      );
    }

    return <Router history={this._history} routes={getRoutes()} />;
  }
}

export default connect()(Root);

function getRoutes() {
  // https://github.com/remix-run/react-router/tree/v3.2.6/docs
  // https://github.com/remix-run/react-router/blob/v3.2.6/docs/API.md#injected-props
  //
  // Wrap enough react-router components around RootWithPageContext to pass in
  // the current page location as a property.
  return (
    <Route>
      <Route path="*" component={RoutesWithPageContext} />
    </Route>
  );
}

const RoutesWithPageContext = ({location}) => (
  <PageContextFromLocation location={location}>
    <Routes />
  </PageContextFromLocation>
);

const Routes = () => {
  const pageContext = useContext(PageContext);
  if (!pageContext) {
    return null;
  }
  switch (pageContext.page) {
    case 'homepage':
      const redesign = Configuration.get('homepage.redesign');
      if (!redesign) {
        return <KotohomesHomePageV1 />;
      }
      return <KotohomesHomePage />;
    case 'article':
      return <KotohomesArticlePage slug={pageContext.slug} />;
    case 'listicle':
      return <KotohomesListiclePage slug={pageContext.slug} />;
    case 'loans':
      return <KotohomesLoansPage />;
    case 'advertise':
      return <KotohomesAdvertisePage />;
    case 'advertise_contact':
      return <KotohomesAdvertiseContactPage />;
    case 'calculator':
      return <KotohomesCalculatorPage slug={pageContext.slug} />;
    case 'listing':
      return <ListingPage listing={pageContext.listing} />;
    case 'listing_agent':
      return (
        <AgentPage
          listingAgent={pageContext.listing_agent}
          listingBroker={pageContext.listing_broker}
          listings={pageContext.listings}
        />
      );
    case 'listing_broker':
      return (
        <BrokerPage listingBroker={pageContext.listing_broker} listings={pageContext.listings} />
      );
    case 'search':
      return <SearchPage />;
    case 'search_splat_page':
      return <SearchSplatPage splat={pageContext.splat} />;
    case 'redirect':
      window.location.href = pageContext.to;
      return null;
    case 'error':
      return (
        <ErrorPage
          title="Unexpected error"
          message="Sorry, something went wrong. Please try again."
        />
      );
  }
  return <NotFound />;
};
