import React from 'react';

import styles from './KotohomesHeader.module.css';
import logo from '../../images/kotohomes-logo.png';
const KotohomesHeader = ({partnerName, partnerUrl}) => {
  return (
    <header className={styles.root}>
      <div className={styles.content}>
        <div className={styles.container}>
          <div className={styles.branding}>
            <a href="/">
              <img className={styles.logoImage} src={logo} alt="Kotohomes" />
            </a>
            <div className={styles.logoBlurb}>
              <div className={styles.partneredWith}>Kotohomes partnered with:</div>
              <div className={styles.break}></div>
              <div className={styles.partnerName}>
                <a href={partnerUrl}>{partnerName}</a>
              </div>
            </div>
          </div>
          <div className={styles.links}>
            <a href="/search">Buy</a>
            <a className={styles.rent} href="/search?type=for_rent">
              Rent
            </a>
            <a href="/loans">Loans</a>
            <div className={styles.spacer}></div>
            <a href="/advertise">Advertise</a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default KotohomesHeader;
