import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {findDOMNode} from 'react-dom';

import {ScheduledFunction, portionOfElementInViewport} from '../../../lib/node_utils';
import {observeDocumentVisibility} from './documentVisibilityObserver';
import {observeScrollPosition, ScrollPositionShape} from './scrollPositionObserver';
import {observeWindowSize, WindowSizeShape} from './windowSizeObserver';
import {getDisplayName} from './util';

/**
 * High-level component that reports the current component visibility.
 * All props are passed through.
 *
 * requiredVisibleRatio - portion of component's client rectangle that must be in viewport
 * for component to be considered visible (e.g. 1/2). 0 < requiredVisibleRatio <= 1
 */
export function observeComponentVisibility(
  WrappedComponent,
  requiredVisibleRatio = Number.MIN_VALUE
) {
  return observeDocumentVisibility(
    observeScrollPosition(
      observeWindowSize(
        class Observer extends Component {
          static displayName = `ComponentVisibleObserver(${getDisplayName(WrappedComponent)})`;

          static propTypes = {
            documentVisible: PropTypes.bool,
            scrollPosition: ScrollPositionShape.isRequired,
            windowDimensions: WindowSizeShape.isRequired,
          };

          state = {componentVisible: false};

          componentDidMount() {
            this._mounted = true;
            ScheduledFunction.wrap(this._checkVisibleImpl).schedule(500);
          }

          componentWillUnmount() {
            this._mounted = false;
            ScheduledFunction.wrap(this._checkVisibleImpl).stop();
          }

          componentDidUpdate(prevProps, _prevState) {
            const {documentVisible, scrollPosition, windowDimensions} = this.props;
            if (
              documentVisible != prevProps.documentVisible ||
              scrollPosition.top !== prevProps.scrollPosition.top ||
              scrollPosition.left !== prevProps.scrollPosition.left ||
              windowDimensions.width !== prevProps.windowDimensions.width ||
              windowDimensions.height !== prevProps.windowDimensions.height
            ) {
              this._checkVisible();
            }
          }

          _checkVisible = () => {
            ScheduledFunction.wrap(this._checkVisibleImpl).debounce(250);
          };

          _checkVisibleImpl = () => {
            if (!this._mounted) {
              return;
            }
            const {documentVisible} = this.props;
            if (!documentVisible && this.state.componentVisible) {
              this.setState({componentVisible: false});
              return;
            }
            const element = findDOMNode(this);
            if (!element) {
              ScheduledFunction.wrap(this._checkVisibleImpl).schedule(250);
              return;
            }
            const componentVisibleRatio = portionOfElementInViewport(element);
            const componentVisible =
              documentVisible && componentVisibleRatio >= requiredVisibleRatio;
            if (this.state.componentVisible !== componentVisible) {
              this.setState({componentVisible});
            }
          };

          render() {
            return <WrappedComponent {...this.props} {...this.state} />;
          }
        }
      )
    )
  );
}

/**
 * Creates a componenetDidUpdate handler which invokes callbacks when:
 *
 * onVisible: component becomes visible when the document is visible
 * onNotVisible: component becomes no longer visible or the document is no longer visible
 * onScroll: component is visible and scrollPosition or window dimensions have changed
 *
 * Usage
 * this._handler = componentVisiblityMakeOnComponentDidUpdate.bind(this)({
 *  onVisible: () => {},
 *  onNotVisible: () => {},
 *  onScroll: () => {}
 * })
 *
 * componentDidUpdate(prevProps, prevState) {
 *   this._handler(prevProps, prevState);
 * ...
 * }
 */
export function componentVisiblityMakeOnComponentDidUpdate({onVisible, onNotVisible, onScroll}) {
  onVisible = onVisible || (() => null);
  onNotVisible = onNotVisible || (() => null);
  onScroll = onScroll || (() => null);
  return (prevProps) => {
    const {componentVisible, scrollPosition, windowDimensions} = this.props;

    if (prevProps.componentVisible !== componentVisible) {
      if (componentVisible) {
        onVisible();
      } else {
        onNotVisible();
      }
    }
    if (
      componentVisible &&
      (scrollPosition.top !== prevProps.scrollPosition.top ||
        scrollPosition.left !== prevProps.scrollPosition.left ||
        windowDimensions.width !== prevProps.windowDimensions.width ||
        windowDimensions.height !== prevProps.windowDimensions.height)
    ) {
      onScroll();
    }
  };
}
