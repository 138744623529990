/**
 * Helper for observering mouse entry on a DOM node
 */
export class MouseEntryObserver {
  constructor(callback) {
    this.callback = callback;
    this.hasMouse = false;
  }

  observe = (element) => {
    if (this.element && element !== this.element) {
      this._cleanupListeners();
    }
    this.element = element;
    if (element) {
      this._addListeners();
    }
  };

  unobserve = () => {
    this._cleanupListeners();
    this.element = null;
  };

  _addListeners = () => {
    const element = this.element;
    if (element) {
      element.addEventListener('mouseenter', this._enter);
      element.addEventListener('mouseleave', this._leave);
    }
  };

  _cleanupListeners = () => {
    const element = this.element;
    if (element) {
      element.removeEventListener('mouseenter', this._enter);
      element.removeEventListener('mouseleave', this._leave);
    }
  };

  _enter = () => {
    if (!this.hasMouse) {
      this.hasMouse = true;
      this.callback(this.hasMouse);
    }
  };

  _leave = () => {
    if (this.hasMouse) {
      this.hasMouse = false;
      this.callback(this.hasMouse);
    }
  };
}
