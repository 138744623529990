import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {isNumber} from 'underscore';
import {List} from 'immutable';

import {formatNumberWithCommas} from '../../lib/node_utils';
import {Listing} from '../../lib/models';
import {parseGeoJSONPolygon} from '../../lib/utils/geo';
import {urlForListing} from '../../lib/support/routing';
import {zeus} from '../../lib/zeus';

const MAX_RESULTS_SIZE = 10;
const DEFAULT_RESULTS_SIZE = 10;

class Listicle extends React.Component {
  static propTypes = {
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    result_limit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    new_listings_only: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    show_agent: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    mls_number: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    ]),
    bounding_area: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    tiles_per_row: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    agent_uid: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    broker_uid: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    property_type: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    zip_codes: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    query: PropTypes.shape({
      filter: PropTypes.object,
      rank: PropTypes.object,
      sort: PropTypes.object,
    }),
  };

  static defaultProps = {
    result_limit: DEFAULT_RESULTS_SIZE,
  };

  state = {
    listings: new List(),
  };

  async componentDidMount() {
    await this._load();
  }

  _handleListingClick = (listing /*, data */) => {
    window.open(urlForListing(listing), '_top');
  };

  render() {
    if (this.state.loading) {
      return null;
    }

    const {listings} = this.state;
    if (listings.size === 0) {
      return null;
    }

    const numberOrRange = (field, unit) => {
      if (!field) {
        return;
      }
      const {normalized_count: value, normalized_count_range: range} = field;
      const formatter = (v) => v;
      const rangeSeparator = '-';
      let content;
      if (range && range.min != null && range.max != null) {
        content = (
          <span>
            <span className="NumberOrRange_from">{formatter(range.min)}</span>
            <span className="NumberOrRange_sep">{rangeSeparator}</span>
            <span className="NumberOrRange_to">{formatter(range.max)}</span>
          </span>
        );
      } else if (value != null) {
        if (value.normalized_count != null) {
          content = formatter(value.normalized_count);
        } else if (isNumber(value)) {
          content = formatter(value);
        }
      }
      if (content) {
        return (
          <span className="_NumberOrRange">
            {content}
            {unit && <span className="_NumberOrRange_unit"> {unit}</span>}
          </span>
        );
      }
    };

    return (
      <section className="listicle">
        <div className="listicle">
          {listings.map((listing, idx) => (
            <div key={idx} className="item">
              <h3>
                {numberOrRange(listing.bedrooms, 'Bedroom')} Home in {listing.city}{' '}
                {listing.price ? `- $${formatNumberWithCommas(listing.price)}` : ''}
              </h3>
              {listing.getPrimaryPhotoUrl('medium') ? (
                <img src={listing.getPrimaryPhotoUrl('medium')} />
              ) : null}
              {(listing.description || '').split(/\n/).map((v, i) => (
                <p key={i}>{v}</p>
              ))}
              <a href={urlForListing(listing)}>View Listing</a>
            </div>
          ))}
        </div>
      </section>
    );
  }

  async _load() {
    this.setState({loading: true});

    const q = Object.assign(
      {
        filter: {
          location: {},
        },
        sort: {},
      },
      this.props.query
    );

    const polygon = parseGeoJSONPolygon(this.props.bounding_area);
    if (polygon) {
      q.filter.location.area = polygon.map(([lat, lng]) => ({lat, lng}));
    } else if (this.props.bounding_area) {
      console.error('Boundary area is not a valid GeoJSON polygon:', this.props.bounding_area);
    }

    const mlsNumber = this.props.mls_number;
    if (mlsNumber) {
      q.filter.listingId = ensureArray(mlsNumber).map((s) => String(s).toLowerCase().trim());
    }

    if (this.props.status) {
      q.filter.status = this.props.status;
    }

    const agentId = this.props.agent_uid;
    if (agentId) {
      q.filter.agent = {ids: ensureArray(agentId)};
    }

    const brokerId = this.props.broker_uid;
    if (brokerId) {
      q.filter.broker = {ids: ensureArray(brokerId)};
    }

    const zips = this.props.zip_codes;
    if (zips) {
      q.filter.location.zipCode = ensureArray(zips);
    }

    if (isTrueProp(this.props.new_listings_only)) {
      q.filter.publishTime = {
        min: moment().startOf('day').subtract(14, 'days').format('YYYY-MM-DD[T]HH:mm:ssZ'),
      };
    }

    q.filter.propertyType = ensureArray(this.props.property_type);

    q.limit = Math.min(MAX_RESULTS_SIZE, Math.max(1, this.props.result_limit));

    const data = await zeus(`/api/query/listings`, {queryObj: q});
    const listings = new List(data.listings.results).map((r) => new Listing(r.listing));
    this.setState({listings, loading: false});
  }
}

function ensureArray(v) {
  if (v != null && !Array.isArray(v)) {
    return [v];
  }
  return v;
}

function isTrueProp(v) {
  return v === true || v === 'true';
}

export default Listicle;
