import React from 'react';
import {createContext, useEffect, useState} from 'react';

export const PageContext = createContext({});

export const PageContextFromLocation = ({location, children}) => {
  const {pathname, search} = location;
  const [pageContext, setPageContext] = useState();
  useEffect(() => {
    let cancelled = false;
    (async () => {
      let newPageContext;
      try {
        const url = `/api/page${pathname}${search}`;
        const resp = await fetch(url);
        if (resp.status === 200) {
          newPageContext = await resp.json();
        } else if (resp.status === 404) {
          newPageContext = {page: 'notfound'};
        } else {
          newPageContext = {page: 'error'};
        }
      } catch (e) {
        newPageContext = {page: 'error'};
      }
      if (!cancelled) {
        const next = {
          ...newPageContext,
          sov: chooseSov(newPageContext.sov_type, newPageContext.sovs, newPageContext.sov_remnants),
        };
        setPageContext(next);
      }
    })();
    return () => {
      cancelled = true;
    };
  }, [pathname, search]);
  if (!pageContext) {
    return null;
  }
  return <PageContext.Provider value={pageContext}>{children}</PageContext.Provider>;
};

function chooseSov(sovType, sovs, sovRemnants) {
  const sessionStorage = window.sessionStorage;
  const prevSov = sessionStorage.getItem('sov'),
    prevSovType = sessionStorage.getItem('sov_type'),
    prevSovAgent = sessionStorage.getItem('sov_agent'),
    prevSovBroker = sessionStorage.getItem('sov_broker');
  let result = null,
    nextSov = null,
    nextSovType = null,
    nextSovAgent = null,
    nextSovBroker = null;
  // TODO if all sovs are remnant then we shouldn't clear any stick sovs!
  if (!sovs && prevSov) {
    if (sovType && prevSovType !== sovType) {
      // last seen sov is of the wrong type (for_sale, for_rent)
      // so drop it
    } else {
      // no geo context, preserve last seen sov
      nextSov = prevSov;
      nextSovType = prevSovType;
      result = {
        id: prevSov,
        type: prevSovType,
        sticky: true,
      };
      if (prevSovAgent) {
        nextSovAgent = prevSovAgent;
        result.agent = {id: prevSovAgent};
      }
      if (prevSovBroker) {
        nextSovBroker = prevSovBroker;
        result.broker = {id: prevSovBroker};
      }
    }
  } else if (sovType && sovs && sovs.length > 0) {
    // pick a sov from our geo context, ideally one that matches our last
    // seen sov.
    result = firstMatchingSov(sovs, prevSov, prevSovAgent, prevSovBroker);
    if (!result) {
      const offset = Math.floor(Math.random() * sovs.length);
      result = sovs[offset];
    }
    if (result) {
      result = {...result, type: sovType};
      nextSov = result.id;
      nextSovType = sovType;
      nextSovAgent = result.agent?.id;
      nextSovBroker = result.broker?.id;
    }
  }
  updateItem('sov', nextSov);
  updateItem('sov_type', nextSovType);
  updateItem('sov_agent', nextSovAgent);
  updateItem('sov_broker', nextSovBroker);
  // TODO Cleanup old session variables, delete this code after a grace period.
  sessionStorage.removeItem('sov_ad');
  sessionStorage.removeItem('sov_ad_url');
  if (!result && sovRemnants) {
    const offset = Math.floor(Math.random() * sovRemnants.length);
    result = sovRemnants[offset];
    result = {...result, type: sovType, remnant: true};
  }
  return result;
}

function firstMatchingSov(sovs, sov, sovAgent, sovBroker) {
  if (sov) {
    for (const candidate of sovs) {
      if (candidate.id === sov) {
        return candidate;
      }
    }
  }
  if (sovAgent) {
    for (const candidate of sovs) {
      if (candidate.agent?.id === sovAgent) {
        return candidate;
      }
    }
  }
  if (sovBroker) {
    for (const candidate of sovs) {
      if (candidate.broker?.id === sovBroker) {
        return candidate;
      }
    }
  }
  return null;
}

function updateItem(key, rawValue) {
  const value = typeof rawValue !== 'undefined' ? rawValue : null;
  const sessionStorage = window.sessionStorage;
  const prev = sessionStorage.getItem(key);
  if (prev !== value) {
    if (value === null) {
      sessionStorage.removeItem(key);
    } else {
      sessionStorage.setItem(key, value);
    }
  }
}
