import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import matter from 'gray-matter';

import Page from '../common/Page';
import Ad from '../Ad';
import KotohomesPageFooter from '../KotohomesPageFooter';
import Configuration from '../../../lib/configuration';

const pageNotFound = `---
title: Page Not Found
---
# Page Not Found
`;

const getMarkdown = async (slug) => {
  if (!/^[a-z0-9-]+$/.test(slug)) {
    return null;
  }
  const url = `/cms/${slug}.md`;
  const resp = await fetch(url);
  if (resp.status !== 200 || !resp.headers.get('content-type').startsWith('text/markdown')) {
    return null;
  }
  return await resp.text();
};

class KotohomesArticlePage extends React.Component {
  state = {data: {}, content: ''};

  componentDidMount() {
    this._loadContent();
  }

  componentDidUpdate(prevProps, _prevState, _snapshot) {
    if (prevProps.slug != this.props.slug) {
      this._loadContent();
    }
  }

  async _loadContent() {
    const raw = (await getMarkdown(this.props.slug)) || pageNotFound;
    const {data, content} = matter(raw);
    this.setState({data, content});
  }

  render() {
    const redesign = Configuration.get('homepage.redesign');
    const title = this.state.data.title || null;
    const content = this.state.content || '';
    return (
      <Page className="KotohomesArticlePage" title={title}>
        <div className="PageContent">
          <div className="Article">
            <ReactMarkdown source={content} />
          </div>
          <div className="Ad">
            <Ad name={redesign ? 'content_skyscraper' : 'right_rail_tile'} />
          </div>
        </div>
        <KotohomesPageFooter />
      </Page>
    );
  }
}

KotohomesArticlePage.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default KotohomesArticlePage;
