import React, {useContext} from 'react';
import PropTypes from 'prop-types';

import Page from '../common/Page';
import Ad from '../Ad';
import SearchWidget from '../../widgets/SearchWidget';
import ListingsWidget from '../../widgets/ListingsWidget';
import AllPartnerSites, {fetchPartners} from '../common/AllPartnerSites';
import DynamicLinks from '../common/DynamicLinks';
import KotohomesPageFooter from '../KotohomesPageFooter';
import Configuration from '../../../lib/configuration';
import {PageContext} from '../../../lib/pageContext';
import SiteAd from '../SiteAd';

class PartnersSection extends React.Component {
  state = {partners: [], partnersByState: {}};

  async componentDidMount() {
    const {partners, partnersByState} = await fetchPartners();
    this.setState({...this.state, partners, partnersByState});
  }

  render() {
    return (
      <Section>
        <SectionHeader
          title={`All Partner Sites (${this.state.partners.length})`}
          iconClass="AllPartnerSitesIcon"
        />
        <AllPartnerSites partnersByState={this.state.partnersByState} />
      </Section>
    );
  }
}
const KotohomesHomePageV1 = () => {
  const {site_ads: siteAds} = useContext(PageContext);
  const hasStoryAdTile = Configuration.get('ads.homepage_story_tile.vendor');
  const cities = Configuration.get('homepage.cities');
  const siteChecksum = Configuration.getSiteChecksum();
  return (
    <Page title="" className="KotohomesHomePageV1">
      <div className="PageContent">
        <Section>
          <SearchWidget />
        </Section>

        {siteAds?.homepage_leaderboard?.length ? (
          <Section>
            <SiteAd slot="homepage_leaderboard" />
          </Section>
        ) : null}

        <Section>
          <div className="TopListings">
            <div className="ShowcaseListing">
              <ListingsWidget
                {...{
                  result_limit: 1,
                  tiles_per_row: 1,
                  query: {
                    filter: {
                      status: 'active',
                      type: 'for_sale',
                    },
                    rank: {
                      location: {
                        city: cities,
                      },
                      price: {
                        value: {
                          min: 750000,
                        },
                      },
                      propertyType: [
                        'residential',
                        'single_family',
                        'house',
                        'townhouse',
                        'new_construction',
                      ],
                      hasPhotos: true,
                    },
                  },
                }}
              />
            </div>
            <div className="Listings">
              <ListingsWidget
                {...{
                  result_limit: 2,
                  tiles_per_row: 1,
                  query: {
                    filter: {
                      status: 'active',
                      type: 'for_sale',
                    },
                    rank: {
                      location: {
                        city: cities,
                      },
                      price: {
                        value: {
                          min: 300000,
                          max: 749999,
                        },
                      },
                      propertyType: [
                        'residential',
                        'single_family',
                        'house',
                        'townhouse',
                        'new_construction',
                      ],
                      hasPhotos: true,
                    },
                  },
                }}
              />
            </div>
            <div className="Ad">
              {siteAds?.homepage_half_page?.length ? (
                <Section>
                  <SiteAd slot="homepage_half_page" />
                </Section>
              ) : (
                <Ad name="right_rail_tile" />
              )}
            </div>
          </div>
        </Section>

        <Section>
          <Ad name="homepage_middle_leaderboard" />
        </Section>

        <Section>
          <div className="Editorial">
            <div className="Links">
              <h3 className="TileHeader">Content Links</h3>
              <div className="Stories">
                <DynamicLinks
                  source={`/api/headlines?c=${encodeURIComponent(siteChecksum)}`}
                  root="headlines"
                />
              </div>
            </div>
            <div className="Thumbnail Listicle1">
              <LinkTile
                title="Big Homes"
                to="/listicle/big-homes"
                imageUrl="/cms/images/box-1.png"
              />
            </div>
            <div className="Thumbnail Listicle2">
              <LinkTile
                title="Top Fixer Uppers"
                to="/listicle/top-fixer-uppers"
                imageUrl="/cms/images/box-2.png"
              />
            </div>
            <div className="Thumbnail Article1">
              {hasStoryAdTile ? (
                <Ad name="homepage_story_tile" />
              ) : (
                <LinkTile
                  title="Buying a Home During a Pandemic"
                  to="/article/buying-a-home-during-a-pandemic"
                  imageUrl="/cms/images/box-3.png"
                />
              )}
            </div>
            <div className="Thumbnail Article2">
              <LinkTile
                title="First-time Buyer Tips"
                to="/article/first-time-buyer-tips"
                imageUrl="/cms/images/box-4.png"
              />
            </div>
            <div className="Thumbnail Listicle3">
              <LinkTile
                title="Million Dollar Plus Homes"
                to="/listicle/million-dollar-plus-homes"
                imageUrl="/cms/images/box-5.png"
              />
            </div>
          </div>
        </Section>

        <Section>
          <SectionHeader title="New Listings" iconClass="NewListingsIcon" />
          <ListingsWidget
            {...{
              result_limit: 4,
              tiles_per_row: 4,
              new_listings_only: true,
              query: {
                filter: {
                  status: 'active',
                  type: 'for_sale',
                },
                rank: {
                  price: {
                    value: {
                      min: 200000,
                      max: null,
                    },
                  },
                  propertyType: [
                    'residential',
                    'single_family',
                    'house',
                    'townhouse',
                    'new_construction',
                  ],
                  hasPhotos: true,
                },
              },
            }}
          />
        </Section>

        <PartnersSection />

        <div className="BottomAd">
          <Ad name="homepage_bottom" />
        </div>
      </div>

      <KotohomesPageFooter includePartnerLinks />
    </Page>
  );
};

const Section = ({children}) => <section className="Section">{children}</section>;
Section.propTypes = {
  children: PropTypes.node.isRequired,
};

const SectionHeader = ({title, iconClass = ''}) => (
  <div className="SectionHeader">
    {iconClass && <div className={`SectionHeader_Icon ${iconClass}`} />}
    <div className="SectionHeader_Title">{title}</div>
  </div>
);
SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  iconClass: PropTypes.string,
};

const LinkTile = ({title, to, imageUrl, imageHeight = 215}) => (
  <a href={to}>
    <h3 className="TileHeader">{title}</h3>
    <div
      style={{
        height: imageHeight,
        background: `url(${imageUrl}) no-repeat center center`,
        backgroundSize: 'cover',
        borderRadius: '0 0 10px 10px',
      }}
    />
  </a>
);
LinkTile.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  imageHeight: PropTypes.number,
};

export default KotohomesHomePageV1;
