import {Set} from 'immutable';

import Configuration from '../configuration';
import Platform from '../platform';

// Reports events to Listhub. For events we can and need to report, see
// http://app.listhub.com/tracking-docs/v3/listing-metrics-js.html.
export default class ListhubMetricsAdapter {
  _lastListingIds = Set();

  static configure() {
    const id = Configuration.get('analytics.listhub.id');
    if (!id) {
      return null;
    }

    // prettier-ignore
    (function(l,i,s,t,h,u,b){l['ListHubAnalyticsObject']=h;l[h]=l[h]||function(){ // eslint-disable-line
      (l[h].q=l[h].q||[]).push(arguments)},l[h].d=1*new Date();u=i.createElement(s), // eslint-disable-line
      b=i.getElementsByTagName(s)[0];u.async=1;u.src=t;b.parentNode.insertBefore(u,b) // eslint-disable-line
    })(window,document,'script','//tracking.listhub.net/la.min.js','lh');

    if (!window.lh) {
      throw new Error("Expected Listhub script to define 'window.lh'");
    }

    window.lh('init', {
      provider: id,
      test: Platform.testMode,
    });

    return new ListhubMetricsAdapter();
  }

  async handleAnalyticsEvent(name, data) {
    switch (name) {
      case 'viewedListing':
        await this._submit('DETAIL_PAGE_VIEWED', data.listing);
        break;
      case 'search':
        this._performedSearch(data.resultSet.results.map((r) => r.listing));
        break;
      case 'sharedListingByEmail':
        await this._submit('SHARED', data.listing);
        break;
      case 'submittedAgentInfoRequestEmail':
        await this._submit('AGENT_EMAIL_SENT', data.listing);
        break;
      case 'submittedBrokerInfoRequestEmail':
        await this._submit('OFFICE_EMAIL_SENT', data.listing);
        break;
    }
  }

  _submit(eventName, listing) {
    const key = listing && (listing.external_listing || {}).listhub_listing_key;
    if (key) {
      this._send(eventName, {lkey: key});
    }
  }

  _submitMultiple(eventName, listings) {
    if (!listings) {
      return;
    }
    const list = listings
      .map((listing) => {
        const key = (listing.external_listing || {}).listhub_listing_key;
        return key ? {lkey: key} : null;
      })
      .filter((v) => v != null)
      .toJS();
    if (list.length > 0) {
      this._send(eventName, list);
    }
  }

  _send(eventName, data) {
    window.lh('submit', eventName, data);
  }

  _performedSearch(listings) {
    const ids = Set(listings.map((l) => l.id));
    if (!this._lastListingIds.equals(ids)) {
      this._submitMultiple('SEARCH_DISPLAY', listings);
    }
    this._lastListingIds = ids;
  }
}
