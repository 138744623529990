import React, {useContext} from 'react';

import styles from './KotohomesHomePage.module.css';
import Page from '../common/Page';
import Ad from '../Ad';
import SearchWidget from '../../widgets/SearchWidget';
import KotohomesPageFooter from '../KotohomesPageFooter';
import Configuration from '../../../lib/configuration';
import {PageContext} from '../../../lib/pageContext';
import SiteAd from '../SiteAd';
import HouseCarousel from '../homepage/HouseCarousel';
import HouseTile from '../homepage/HouseTile';
import HeadlinesTile from '../homepage/HeadlinesTile';

import logoAppStore from '../../../images/Download_on_the_App_Store_Badge_US-UK_blk_092917.png';
import logoPlayStore from '../../../images/google-play-badge.png';

// Order is important here, we pick the first N slots to fill with ads.
const possibleBrandedAdTileSlots = [8, 2, 3, 4, 13, 14, 15, 16, 9, 10];

const KotohomesHomePage = () => {
  const {display_sov_leaderboard_ad: displaySovLeaderboardAd, listings} = useContext(PageContext);
  const hasPartnerAdTile = !!Configuration.get('ads.homepage_story_tile.vendor');
  const numBrandedAdTile = Configuration.get('ads.home_branded_tile.count');
  const brandedAdTileSlots = new Set(possibleBrandedAdTileSlots.slice(0, numBrandedAdTile));
  return (
    <Page title="" className={styles.root}>
      <div className={styles.content}>
        <section className={styles.search}>
          <SearchWidget
            locationPlaceholder="Address, City, or Zip Code"
            hide_title
            type="for_sale"
            hide_mls_field
            hide_query_beds
            hide_query_baths
            hide_query_price
            hide_query_more
          />
        </section>

        {displaySovLeaderboardAd && (
          <div className={styles.slot6}>
            <SiteAd slot="homepage_slot6">
              <Ad name="home_branded_leaderboard" extraTargeting={{kh_home_slot: '6'}} />
            </SiteAd>
          </div>
        )}

        <div className={styles.contentWells}>
          <div className={styles.slot1}>
            <SiteAd slot="homepage_slot1">
              <HouseCarousel listings={listings?.slice(0, 2)} />
            </SiteAd>
          </div>
          <div className={styles.slot2}>
            <SiteAd slot="homepage_slot2">
              {brandedAdTileSlots.has(2) ? (
                <Ad name="home_branded_tile" extraTargeting={{kh_home_slot: '2'}} />
              ) : (
                <HouseTile listing={listings?.[4]} />
              )}
            </SiteAd>
          </div>
          <div className={styles.slot3}>
            <SiteAd slot="homepage_slot3">
              {brandedAdTileSlots.has(3) ? (
                <Ad name="home_branded_tile" extraTargeting={{kh_home_slot: '3'}} />
              ) : (
                <HouseTile listing={listings?.[5]} />
              )}
            </SiteAd>
          </div>
          <div className={styles.slot4}>
            <SiteAd slot="homepage_slot4">
              {brandedAdTileSlots.has(4) ? (
                <Ad name="home_branded_tile" extraTargeting={{kh_home_slot: '4'}} />
              ) : (
                <HouseTile listing={listings?.[6]} />
              )}
            </SiteAd>
          </div>
          <div className={styles.slot5}>
            <SiteAd slot="homepage_slot5">
              {hasPartnerAdTile ? (
                <Ad name="homepage_story_tile" extraTargeting={{kh_home_slot: '5'}} />
              ) : (
                <HouseTile listing={listings?.[7]} />
              )}
            </SiteAd>
          </div>
          <div className={styles.slot7}>
            <SiteAd slot="homepage_slot7">
              <HeadlinesTile />
            </SiteAd>
          </div>
          <div className={styles.slot8}>
            <SiteAd slot="homepage_slot8">
              {brandedAdTileSlots.has(8) ? (
                <Ad name="home_branded_tile" extraTargeting={{kh_home_slot: '8'}} />
              ) : (
                <HouseTile listing={listings?.[8]} />
              )}
            </SiteAd>
          </div>
          <div className={styles.slot9}>
            <SiteAd slot="homepage_slot9">
              {brandedAdTileSlots.has(9) ? (
                <Ad name="home_branded_tile" extraTargeting={{kh_home_slot: '9'}} />
              ) : (
                <HouseTile listing={listings?.[9]} />
              )}
            </SiteAd>
          </div>
          <div className={styles.slot10}>
            <SiteAd slot="homepage_slot10">
              {brandedAdTileSlots.has(10) ? (
                <Ad name="home_branded_tile" extraTargeting={{kh_home_slot: '10'}} />
              ) : (
                <HouseTile listing={listings?.[10]} />
              )}
            </SiteAd>
          </div>
          <div className={styles.slot11}>
            <SiteAd slot="homepage_slot11">
              <HouseCarousel listings={listings?.slice(2, 4)} />
            </SiteAd>
          </div>
          <div className={styles.slot13}>
            <SiteAd slot="homepage_slot13">
              {brandedAdTileSlots.has(13) ? (
                <Ad name="home_branded_tile" extraTargeting={{kh_home_slot: '13'}} />
              ) : (
                <HouseTile listing={listings?.[11]} />
              )}
            </SiteAd>
          </div>
          <div className={styles.slot14}>
            <SiteAd slot="homepage_slot14">
              {brandedAdTileSlots.has(14) ? (
                <Ad name="home_branded_tile" extraTargeting={{kh_home_slot: '14'}} />
              ) : (
                <HouseTile listing={listings?.[12]} />
              )}
            </SiteAd>
          </div>
          <div className={styles.slot15}>
            <SiteAd slot="homepage_slot15">
              {brandedAdTileSlots.has(15) ? (
                <Ad name="home_branded_tile" extraTargeting={{kh_home_slot: '15'}} />
              ) : (
                <HouseTile listing={listings?.[13]} />
              )}
            </SiteAd>
          </div>
          <div className={styles.slot16}>
            <SiteAd slot="homepage_slot16">
              {brandedAdTileSlots.has(16) ? (
                <Ad name="home_branded_tile" extraTargeting={{kh_home_slot: '16'}} />
              ) : (
                <HouseTile listing={listings?.[14]} />
              )}
            </SiteAd>
          </div>
        </div>
      </div>

      <div className={styles.slot12}>
        <SiteAd slot="homepage_slot12">
          <Ad name="home_partner_leaderboard" extraTargeting={{kh_home_slot: '12'}} />
        </SiteAd>
      </div>

      <div className={styles.appStore}>
        <a href="https://apps.apple.com/us/app/kotohomes/id1548555734">
          <img className={styles.appleButton} src={logoAppStore} alt="App Store" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.kotopad.prod">
          <img className={styles.googleButton} src={logoPlayStore} alt="Play Store" />
        </a>
      </div>

      <KotohomesPageFooter />
    </Page>
  );
};

export default KotohomesHomePage;
