import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {IoIosArrowBack} from 'react-icons/io';
import {withRouter} from 'react-router';
import {compact} from 'underscore';

import {urlForBackToSearch} from '../../../lib/support/routing';
import KotohomesHeaderV1 from '../KotohomesHeaderV1';
import CustomHeader from '../CustomHeader';
import Footer from '../Footer';
import Configuration from '../../../lib/configuration';
import Platform from '../../../lib/platform';
import {IconLink} from './IconLink';
import KotohomesHeader from '../KotohomesHeader';

class Page extends React.Component {
  static propTypes = {
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
    topAdSlot: PropTypes.element,
    showBacklink: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
    title: PropTypes.string,
    nakedHeading: PropTypes.node,
    heading: PropTypes.node,
  };

  static defaultProps = {
    showBacklink: false,
  };

  componentDidMount() {
    if (this.props.title !== null) {
      this._updateTitle();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.title !== this.props.title) {
      this._updateTitle();
    }
  }

  render() {
    const {topAdSlot, heading, nakedHeading, showBacklink} = this.props;
    const siteTitle = Configuration.get('title');
    const partnerUrl = Configuration.get('partner_url');
    const isKotohomes = Configuration.get('branding.kotohomes');
    const customHeader = Configuration.get('branding.header');
    const redesign = Configuration.get('homepage.redesign');
    const hasCustomHeader = customHeader?.src?.length > 0 && customHeader?.height;

    return (
      <div className={classNames('Page', this.props.className)}>
        {Platform.testMode && (
          <div className="Page_developmentMode">
            Test mode. Some functionality will be simulated.
          </div>
        )}

        {topAdSlot && (
          <div className="Page_top_ad">
            <div>{topAdSlot}</div>
          </div>
        )}
        {hasCustomHeader && <CustomHeader src={customHeader.src} height={customHeader.height} />}
        {isKotohomes &&
          !hasCustomHeader &&
          (redesign ? (
            <KotohomesHeader partnerName={siteTitle} partnerUrl={partnerUrl} />
          ) : (
            <KotohomesHeaderV1 title={siteTitle} />
          ))}
        <Footer />

        {(showBacklink || heading || nakedHeading) && (
          <div className="Page_top">
            {showBacklink && (
              <div className="Page_top_backlink">
                <IconLink
                  icon={IoIosArrowBack}
                  size={20}
                  href={urlForBackToSearch(this.props.location.search)}>
                  <span className="t">Back to search</span>
                </IconLink>
              </div>
            )}
            {heading && <div className="Page_top_heading">{heading}</div>}
            {nakedHeading && <div className="Page_top_naked_heading">{nakedHeading}</div>}
          </div>
        )}
        <div className="Page_content" data-is-scroll-root="true">
          {this.props.children}
        </div>
      </div>
    );
  }

  _updateTitle() {
    const title = this.props.title || '';
    Platform.setViewTitle(compact([title, Configuration.get('title')]).join(' – '));
  }
}

export default withRouter(Page);
