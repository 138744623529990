import React, {useContext} from 'react';
import * as ReactDOM from 'react-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {omit} from 'underscore';
import Recaptcha from 'react-recaptcha';

import {formatNumberWithCommas} from '../../../lib/node_utils';
import {Spinner} from '../Spinner.react';
import {analyticsEvent} from '../../../lib/actions';
import Platform from '../../../lib/platform';
import TextInput from '../common/TextInput';
import Configuration from '../../../lib/configuration';
import {shouldSendToOpcity} from '../../../lib/lead_routing';
import {PageContext} from '../../../lib/pageContext';
import {Checkbox} from '../Checkbox.react';

const INITIAL_FORM_STATE = {
  body: '',
  name: '',
  email: '',
  phone: '',
  houseToSell: false,
  recaptchaResponse: null,
};

class AgentContactForm extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    listing: PropTypes.object,
    title: PropTypes.string,
    broker: PropTypes.object,
    agent: PropTypes.object,
    triggerOpen: PropTypes.bool,
    sov: PropTypes.shape({
      id: PropTypes.string,
      remnant: PropTypes.bool,
      agent: PropTypes.shape({
        name: PropTypes.string,
      }),
      broker: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  };

  static defaultProps = {
    listing: null,
    title: 'Get more information about this listing',
    broker: null,
    agent: null,
    triggerOpen: false,
  };

  constructor(props) {
    super(props);
    this.state = {userExpanded: false, status: 'not_sent', ...INITIAL_FORM_STATE};
  }

  componentDidMount() {
    this._setDefaultMessage();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.triggerOpen && !this.props.triggerOpen) {
      this.setState({userExpanded: true});
    }
  }

  _sendToOpcity = () => {
    const {listing, agent, broker, sov} = this.props;
    return shouldSendToOpcity({listing, agent, broker, sov});
  };

  _analyticsParams = () => {
    const {listing, sov} = this.props;
    return {
      kh_sov_type: sov?.id ? sov.type : null,
      kh_sov_id: sov?.id,
      kh_sov_reason: sov?.id ? (sov.remnant ? 'remnant' : 'default') : null,
      kh_listing_type: listing?.type,
      kh_listing_zipcode: listing?.zip_code,
      kh_listing_id: listing?.id,
    };
  };

  render() {
    const {listing, sov} = this.props;
    const shouldBeExpanded =
      this.state.name.length > 0 || this.state.phone.length > 0 || this.state.email.length > 0;
    const isExpanded = shouldBeExpanded || this.state.userExpanded;

    const nameValid = this.state.name.length > 0;
    const emailValid = /.+@.+\.[a-z][a-z]+$/i.test(this.state.email);
    const phoneValid =
      this.state.phone.length > 0 &&
      /^1?\d{10}$/i.test((this.state.phone || '').replace(/[^\d]/g, ''));
    const hideExtraElements = !isExpanded;

    let phoneNumber, phoneNumberName, phoneNumberClass;
    if (sov?.agent) {
      phoneNumber = sov.agent.phone;
      phoneNumberName = sov.agent.name;
      phoneNumberClass = sov.remnant ? 'sov-agent-remnant' : 'sov-agent';
    } else if (sov?.broker) {
      phoneNumber = sov.broker.phone;
      phoneNumberName = sov.broker.name;
      phoneNumberClass = sov.remnant ? 'sov-broker-remnant' : 'sov-broker';
    } else if (this._sendToOpcity()) {
      if (listing?.type === 'for_sale') {
        phoneNumber = Configuration.get('lead_routing.buy.opcity.phone');
        phoneNumberName = 'Realtor';
        phoneNumberClass = 'partner-opcity';
      } else if (listing?.type === 'for_rent') {
        phoneNumber = Configuration.get('lead_routing.rent.opcity.phone');
        phoneNumberName = 'Realtor';
        phoneNumberClass = 'partner-opcity';
      }
    }

    const recaptchaSiteKey = Configuration.get('services.recaptcha.site_key');

    let content;

    switch (this.state.status) {
      case 'failed':
        content = (
          <div className="AgentContactForm_failure">
            <p>
              {"We're sorry, but we were unable to process your request. Please try again later."}
            </p>
            <button onClick={this._handleResetClick}>OK</button>
          </div>
        );
        break;
      case 'sent':
        content = (
          <div className="AgentContactForm_success">
            <p>
              Thank you for your submission, you will be contacted shortly to be connected with a
              local real estate expert.
            </p>
            <button onClick={this._handleResetClick}>OK</button>
          </div>
        );
        break;
      case 'sending':
        content = (
          <div className="AgentContactForm_success">
            <Spinner />
            <p>{'Submitting…'}</p>
          </div>
        );
        break;
      case 'not_sent':
        content = (
          <form
            method="post"
            action={`/api/email/agent`}
            charSet="utf-8"
            onSubmit={this._handleSubmit}
            ref="form">
            <ul>
              <li>
                <TextInput
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={this.state.name}
                  onChange={(name) => this.setState({name})}
                  onFocus={() => this.setState({userExpanded: true})}
                  required
                  valid={nameValid}
                />
              </li>
              <li>
                <TextInput
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={this.state.email}
                  onChange={(email) => this.setState({email})}
                  onFocus={() => this.setState({userExpanded: true})}
                  required
                  valid={emailValid}
                />
              </li>
              <li>
                <TextInput
                  type="tel"
                  name="phone"
                  placeholder="Phone"
                  value={this.state.phone}
                  onChange={(phone) => this.setState({phone})}
                  onFocus={() => this.setState({userExpanded: true})}
                  required
                  valid={phoneValid}
                />
              </li>
              {!hideExtraElements && (
                <li className="AgentContactForm_message">
                  <TextInput
                    type="textarea"
                    name="body"
                    placeholder="Optional message"
                    value={this.state.body}
                    onChange={(body) => this.setState({body})}
                    valid={this.state.body.trim().length > 0}
                  />
                </li>
              )}
              {!hideExtraElements && (
                <li className="AgentContactForm_housetosell">
                  <Checkbox
                    label="I have a house to sell"
                    checked={this.state.houseToSell}
                    onChange={(houseToSell) => this.setState({houseToSell})}
                  />
                </li>
              )}
              {!hideExtraElements && (
                <li className="AgentContactForm_recaptcha" aria-label="CAPTCHA">
                  {recaptchaSiteKey && (
                    <Recaptcha
                      elementID="agent-contact-captcha"
                      sitekey={recaptchaSiteKey}
                      verifyCallback={(recaptchaResponse) => this.setState({recaptchaResponse})}
                      expiredCallback={() => this.setState({recaptchaResponse: null})}
                    />
                  )}
                </li>
              )}
              {!hideExtraElements && (
                <li className="AgentContactForm_disclaimer">
                  <TCPADisclaimer
                    listing={this.props.listing}
                    sov={this.props.sov}
                    opcity={this._sendToOpcity()}
                  />
                </li>
              )}
            </ul>
            {!hideExtraElements && (
              <div className="AgentContactForm_bottom">
                {this.state.status === 'sending' ? (
                  <span className="AgentContactForm_bottom_progress">Submitting…</span>
                ) : (
                  <span className="AgentContactForm_bottom_button">
                    <input
                      type="submit"
                      value="Send request"
                      aria-label="Send request"
                      disabled={
                        !(nameValid && phoneValid && emailValid && this.state.recaptchaResponse)
                      }
                    />
                  </span>
                )}
                {phoneNumber && (
                  <span className="AgentContactForm_call">
                    <a
                      className={phoneNumberClass}
                      href="#"
                      onClick={(e) => this._handleClickToCall(e, phoneNumberName, phoneNumber)}>
                      Call
                    </a>
                  </span>
                )}
                <span className="AgentContactForm_bottom_clear_button">
                  <input
                    type="submit"
                    aria-label="Clear"
                    value="Clear"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.props.dispatch(
                        analyticsEvent('lead_form_cancel', this._analyticsParams())
                      );
                      this.setState({...INITIAL_FORM_STATE, userExpanded: false});
                      this._setDefaultMessage();
                    }}
                  />
                </span>
              </div>
            )}
            {listing && <input type="hidden" name="listing_id" value={listing.id} />}
          </form>
        );
    }

    return (
      <div className="AgentContactForm">
        {isExpanded ? (
          <span>
            <h3>{this.props.title}</h3>
            {content}
          </span>
        ) : (
          <span className="AgentContactForm_mobile_expand_button">
            <input
              type="submit"
              value="Get more information about this listing"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.props.dispatch(analyticsEvent('lead_form_view', this._analyticsParams()));
                this.setState({userExpanded: true});
              }}
            />
          </span>
        )}
      </div>
    );
  }

  _setDefaultMessage = () => {
    const {listing} = this.props;
    if (listing) {
      const price = listing.price || listing.rent;
      const priceSegment = price ? ` for $${formatNumberWithCommas(price)}.` : '.';
      const address = listing.street_address || 'this property';
      this.setState({body: `I'd like more information on ${address}${priceSegment}`});
    }
  };

  _handleClickToCall = (event, phoneNumberName, phoneNumber) => {
    event.preventDefault();
    this.props.dispatch(analyticsEvent('lead_form_call', this._analyticsParams()));
    if (!Platform.dialPhone(phoneNumber)) {
      Platform.alert(`The phone number for ${phoneNumberName} is ${phoneNumber}`);
    }
  };

  _handleResetClick = (event) => {
    event.preventDefault();
    this.setState({status: 'not_sent'});
  };

  _handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({status: 'sending'});

    const {broker, agent, listing, sov} = this.props;
    try {
      const tcpaDisclaimer = this._getTCPADisclaimerText();
      const response = await fetch(`/api/email/agent`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-recaptcha-response': this.state.recaptchaResponse,
        },
        body: JSON.stringify(
          Object.assign(
            {
              test_mode: Platform.testMode ? true : undefined,
              listing_id: listing ? listing.id : null,
              sov_id: sov ? sov.id : null,
              sov_remnant: sov?.remnant ? true : undefined,
              tcpa_disclaimer: tcpaDisclaimer,
              // None of these are used by the new backend.
              // broker_id: broker ? broker.id : null,
              // agent_id: agent ? agent.id : null,
              house_to_sell: this.state.houseToSell,
            },
            // magic to expose most of our internal state to the backend
            omit(this.state, 'status', 'userExpanded', 'houseToSell')
          )
        ),
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      this.props.dispatch(analyticsEvent('lead_form_submit', this._analyticsParams()));

      const params = {
        listing,
        requesterEmail: this.state.email,
        requesterPhone: this.state.phone,
        message: this.state.body,
      };
      let action;
      if (agent?.email) {
        action = 'submittedAgentInfoRequestEmail';
        params.agent = agent;
      } else if (broker?.email) {
        action = 'submittedBrokerInfoRequestEmail';
        params.broker = broker;
      }
      if (action) {
        this.props.dispatch(analyticsEvent(action, params));
      }

      this.setState({status: 'sent', ...INITIAL_FORM_STATE});
    } catch (err) {
      console.error(err);
      this.setState({status: 'failed'});
    }
  };

  _getTCPADisclaimerText = () => {
    const element = document.createElement('div');
    ReactDOM.render(
      <TCPADisclaimer
        listing={this.props.listing}
        sov={this.props.sov}
        opcity={this._sendToOpcity()}
      />,
      element
    );
    return element.innerText;
  };
}

const TCPADisclaimer = ({listing, sov, opcity}) => {
  const isKotohomes = Configuration.get('branding.kotohomes');
  const siteTitle = Configuration.get('title');
  return (
    <div className="tcpa-disclaimer">
      By proceeding, you consent to receive calls, texts and voicemails at the number you provided
      (may be recorded and may be autodialed and use pre recorded and artificial voices), and email,
      from <span className="site-partner">{isKotohomes ? 'Kotohomes' : siteTitle}</span>
      {sov?.agent ? (
        <>
          ,{' '}
          <span className={sov.remnant ? 'sov-agent-remnant' : 'sov-agent'}>{sov.agent.name}</span>
        </>
      ) : sov?.broker ? (
        <>
          ,{' '}
          <span className={sov.remnant ? 'sov-broker-remnant' : 'sov-broker'}>
            {sov.broker.name}
          </span>
        </>
      ) : (
        ''
      )}
      {listing.agent?.email ? (
        <>
          , <span className="listing-agent">{listing.agent.name || 'listing agent'}</span>
        </>
      ) : listing.broker?.email ? (
        <>
          , <span className="listing-broker">{listing.broker.name || 'listing broker'}</span>
        </>
      ) : (
        ''
      )}
      {opcity ? (
        <>
          , <span className="partner-opcity">Opcity, realtor.com</span>
        </>
      ) : (
        ''
      )}{' '}
      and their network of service providers about your inquiry and other home-related matters.{' '}
      <i>
        Msg/data rates may apply. This consent applies even if you are on a do not call list and is
        not a condition of any purchase.
      </i>
    </div>
  );
};

const AgentContactFormWithDispatch = connect()(AgentContactForm);

const AgentContactFormWithPageContext = (props) => {
  const {sov} = useContext(PageContext);
  return <AgentContactFormWithDispatch {...props} sov={sov} />;
};

export default AgentContactFormWithPageContext;
