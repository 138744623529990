import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {isEqual} from 'underscore';
import {withRouter} from 'react-router';

import Configuration from '../../lib/configuration';
import {createAd} from '../../lib/ads';
import {PageContext} from '../../lib/pageContext';
import AdContainer from './AdContainer';

let adCounter = 0;

class Ad extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    targeting: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const adNumber = adCounter++;
    this._adId = `Ad-${adNumber}`;
    this._config = Configuration.get(`ads.${props.name}`);
  }

  componentDidMount() {
    if (!this._config?.vendor) {
      return;
    }
    this.createAd();
  }

  componentWillUnmount() {
    this.removeAd();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this._config?.vendor) {
      return;
    }
    if (!isEqual(this.props.targeting, prevProps.targeting)) {
      this.createAd();
    }
  }

  createAd = () => {
    this.removeAd();
    const adName = this._config.size ? `${this.props.name}_${this._config.size}` : this.props.name;
    this._cleanup = createAd(
      this._adId,
      this._config.vendor,
      adName,
      this._config.slot,
      this.props.targeting
    );
  };

  removeAd = () => {
    if (this._cleanup) {
      const cleanup = this._cleanup;
      this._cleanup = null;
      cleanup();
    }
  };

  render() {
    return <div id={this._adId}></div>;
  }
}

const TargetAd = ({name, location, extraTargeting = null}) => {
  const {page, sov, listing} = useContext(PageContext);
  const targeting = {
    kh_env: Configuration.get('deploy_environment'),
    kh_site_type: Configuration.get('type'),
    kh_site_name: Configuration.get('name'),
    kh_organization: Configuration.get('org'),
  };
  if (sov?.id && !sov.remnant) {
    // Expose SOV targeting on pages when available
    targeting.kh_sov_id = sov.id;
    targeting.kh_sov_type = sov.type;
  }
  if (page === 'search' || page === 'listing') {
    if (location.query.territory) {
      targeting.kh_search_territory = location.query.territory;
    }
    if (location.query.zip_code) {
      targeting.kh_search_zipcode = location.query.zip_code;
    }
  }
  if (page === 'listing') {
    if (listing?.type) {
      targeting.kh_listing_type = listing?.type;
    }
    if (listing?.zip_code) {
      targeting.kh_listing_zipcode = listing?.zip_code;
    }
  }
  Object.assign(targeting, extraTargeting);
  return (
    <AdContainer name={name}>
      <Ad name={name} targeting={targeting} />
    </AdContainer>
  );
};

export default withRouter(TargetAd);
