import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import Page from '../common/Page';
import {
  getListingQueryDescriptionTerms,
  ComplexTerm,
  AttributeTerm,
} from '../../../lib/listings/listingQueryDescription';
import ListingSearch from '../ListingSearch';
import ListingQuery, {
  ListingSearchResultSet,
  parseListingQueryString,
} from '../../../lib/search/ListingQuery';
import {SearchTabs} from '../search/SearchTabs';
import {zeus} from '../../../lib/zeus';
import {analyticsEvent} from '../../../lib/actions';
import Ad from '../Ad';

const SearchPage = ({location, dispatch}) => {
  const {search} = location;
  const [initialQueryReady, setInitialQueryReady] = useState(false);
  const [initialResultSetReady, setInitialResultSetReady] = useState(false);
  const [query, setQuery] = useState(new ListingQuery());
  const [resultSet, setResultSet] = useState(new ListingSearchResultSet());
  useEffect(() => {
    let cancelled = false;
    (async () => {
      const q = await parseListingQueryString(search);
      if (!cancelled) {
        setQuery(q);
        setInitialQueryReady(true);
      }
    })();
    return () => {
      cancelled = true;
    };
  }, [search]);
  useEffect(() => {
    if (!initialQueryReady) {
      return;
    }
    let cancelled = false;
    (async () => {
      const queryObj = Object.assign(query.toGraphQL(), {
        page: query.getPage(),
      });
      const data = await zeus(`/api/query/listings`, {queryObj});
      if (!cancelled) {
        const rs = ListingSearchResultSet.fromObject(data.listings);
        setResultSet(rs);
        setInitialResultSetReady(true);
      }
    })();
    return () => {
      cancelled = true;
    };
  }, [query, initialQueryReady]);
  useEffect(() => {
    if (!initialResultSetReady) {
      return;
    }
    dispatch(analyticsEvent('search', {resultSet}));
  }, [dispatch, initialResultSetReady, resultSet]);
  return (
    <Page
      className="SearchPage"
      title={initialQueryReady ? describeSearch(query) : null}
      nakedHeading={<SearchTabs resultSet={resultSet} />}
      topAdSlot={<Ad name="search_header" />}>
      <ListingSearch
        query={query}
        initialQueryReady={initialQueryReady}
        initialResultSetReady={initialResultSetReady}
        resultSet={resultSet}
      />
    </Page>
  );
};

function describeSearch(query) {
  let terms = getListingQueryDescriptionTerms(query)
    .toList()
    .sortBy((term) => {
      if (term instanceof AttributeTerm) {
        switch (term.attribute) {
          case 'bedrooms':
          case 'bathrooms':
          case 'type':
            return 0;
          default:
            return 1;
        }
      } else if (term instanceof ComplexTerm) {
        switch (term.key) {
          case 'price':
            return 0;
          default:
            return 1;
        }
      }
      return term;
    });

  let description = 'Search';
  if (!terms.isEmpty()) {
    const limit = 4;
    const truncate = terms.length > limit;
    if (truncate) {
      terms = terms.slice(0, limit);
    }
    description += ` — ${terms.map((term) => term.description).join(', ')}`;
    if (truncate) {
      description += `... (${terms.size - limit} more)`;
    }
  }
  return description;
}

export default connect()(withRouter(SearchPage));
