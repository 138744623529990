import React, {useContext} from 'react';

import styles from './SiteAd.module.css';
import {PageContext} from '../../lib/pageContext';

const sizes = {
  homepage_leaderboard: styles.homepage_leaderboard,
  homepage_half_page: styles.homepage_half_page,
};

const SiteAd = ({slot, children}) => {
  const {site_ads: siteAds} = useContext(PageContext);
  const ads = siteAds ? siteAds[slot] : null;
  if (!ads?.length) {
    return children;
  }
  const offset = Math.floor(Math.random() * ads.length);
  const {id, url} = ads[offset];
  let content;
  switch (slot) {
    case 'homepage_leaderboard':
      content = (
        <picture>
          <source
            srcSet={`/api/images/ad/${encodeURIComponent(id)}/728x90`}
            media="(min-width: 728px)"
          />
          <source srcSet={`/api/images/ad/${encodeURIComponent(id)}/320x50`} />
          <img src="" />
        </picture>
      );
      break;
    case 'homepage_half_page':
      content = <img src={`/api/images/ad/${encodeURIComponent(id)}/300x600`} />;
      break;
    case 'homepage_slot2':
    case 'homepage_slot3':
    case 'homepage_slot4':
    case 'homepage_slot5':
    case 'homepage_slot7':
    case 'homepage_slot8':
    case 'homepage_slot9':
    case 'homepage_slot10':
    case 'homepage_slot13':
    case 'homepage_slot14':
    case 'homepage_slot15':
    case 'homepage_slot16':
      content = <img src={`/api/images/ad/${encodeURIComponent(id)}/300x250`} />;
      break;
    case 'homepage_slot1':
    case 'homepage_slot11':
      content = (
        <picture>
          <source
            srcSet={`/api/images/ad/${encodeURIComponent(id)}/970x250`}
            media="(min-width: 970px)"
          />
          <source srcSet={`/api/images/ad/${encodeURIComponent(id)}/300x600`} />
          <img src="" />
        </picture>
      );
      break;
    case 'homepage_slot6':
    case 'homepage_slot12':
      content = (
        <picture>
          <source
            srcSet={`/api/images/ad/${encodeURIComponent(id)}/970x90`}
            media="(min-width: 970px)"
          />
          <source srcSet={`/api/images/ad/${encodeURIComponent(id)}/320x50`} />
          <img src="" />
        </picture>
      );
      break;
  }
  if (!content) {
    return children;
  }
  const sizeClass = sizes[slot] || '';
  return (
    <div className={styles.root}>
      <a href={url} className={sizeClass}>
        {content}
      </a>
    </div>
  );
};
export default SiteAd;
