import React from 'react';
import PropTypes from 'prop-types';

import Page from '../common/Page';
import Ad from '../Ad';
import Listicle from '../../widgets/Listicle';
import KotohomesPageFooter from '../KotohomesPageFooter';
import Configuration from '../../../lib/configuration';

const listicles = {
  'big-homes': {
    title: 'Big Homes',
    query: {
      filter: {
        status: 'active',
        type: 'for_sale',
      },
      rank: {
        squareFootage: {
          facets: {
            min: '10000',
          },
          value: {
            min: null,
            max: null,
          },
        },
        price: {
          value: {
            min: null,
            max: 999999,
          },
        },
        propertyType: ['residential'],
        hasPhotos: true,
      },
    },
  },
  'top-fixer-uppers': {
    title: 'Top Fixer Uppers',
    query: {
      filter: {
        status: 'active',
        type: 'for_sale',
      },
      rank: {
        price: {
          value: {
            min: null,
            max: 20000,
          },
        },
        bathrooms: {
          value: 1.0,
        },
        propertyType: ['residential'],
        hasPhotos: true,
      },
    },
  },
  'million-dollar-plus-homes': {
    title: 'Million Dollar Plus Homes',
    query: {
      filter: {
        status: 'active',
        type: 'for_sale',
      },
      rank: {
        price: {
          value: {
            min: 1000000,
            max: null,
          },
        },
        propertyType: ['residential'],
        hasPhotos: true,
      },
    },
  },
};

class KotohomesListiclePage extends React.Component {
  render() {
    const redesign = Configuration.get('homepage.redesign');
    const {title, query} = listicles[this.props.slug] || {title: 'Page not found'};
    return (
      <Page className="KotohomesListiclePage" title={title}>
        <div className="PageContent">
          <h1 className="Title">{title}</h1>
          <div className="Listicle">{query && <Listicle query={query} />}</div>
          <div className="Ad">
            <Ad name={redesign ? 'content_skyscraper' : 'right_rail_tile'} />
          </div>
        </div>
        <KotohomesPageFooter />
      </Page>
    );
  }
}

KotohomesListiclePage.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default KotohomesListiclePage;
