import React from 'react';
import PropTypes from 'prop-types';

import Page from '../common/Page';
import Ad from '../Ad';
import {CMAffordabilityCalculator} from '../listings/CMAffordabilityCalculator';
import {CMMortgageCalculatorPie} from '../listings/CMMortgageCalculatorPie';
import {CMRentVsBuyCalculator} from '../listings/CMRentVsBuyCalculator';
import Configuration from '../../../lib/configuration';
import KotohomesPageFooter from '../KotohomesPageFooter';

const Colors = {
  primary: `#399CB8`,
  secondary: `#FF6249`,
  secondaryDark: `#DC5046`,
};
class KotohomesCalculatorPage extends React.Component {
  render() {
    const redesign = Configuration.get('homepage.redesign');
    const cordlessPid = Configuration.get('ads.cordless_pid');
    const widgets = {
      affordability: {
        title: 'Affordability Calculator',
        widget: (
          <CMAffordabilityCalculator
            pid={cordlessPid}
            zip="12345"
            address="123 W Main St."
            apt=""
            downPayment="90000"
            propTax="1.66"
            sliderColor={[Colors.primary, Colors.secondaryDark, Colors.secondary]}
            linkColor={Colors.primary}
          />
        ),
      },
      mortgage: {
        title: 'Mortgage Calculator',
        widget: (
          <CMMortgageCalculatorPie
            pid={cordlessPid}
            zip="85233"
            address="123 W Main St."
            price="350000"
            downPayment="60000"
            interestRate="3.75"
            hoa="30"
            homeIns="1020"
            propTax="0.75"
            chartColor={[
              Colors.primary, // Principal & Interest
              Colors.secondary, // Property Taxes
              '#f29d2e', // Home Insurance
              '#999', // HOA
              Colors.secondaryDark, // Mortgage Insurance & Other
            ]}
            linkColor={Colors.primary}
          />
        ),
      },
      'rent-vs-buy': {
        title: 'Rent Vs. Buy Calculator',
        widget: (
          <CMRentVsBuyCalculator
            pid={cordlessPid}
            zip="12345"
            address="123 W Main St."
            sliderColor={[Colors.primary, Colors.secondary]}
            linkColor={Colors.primary}
          />
        ),
      },
    };
    const {title, widget} = widgets[this.props.slug] || {title: 'Page not found'};

    return (
      <Page className="KotohomesCalculatorPage" title={title}>
        <div className="PageContent">
          <h1 className="Title">{title}</h1>
          <div className="Calculator">{widget}</div>
          <div className="Ad">
            <Ad name={redesign ? 'content_skyscraper' : 'right_rail_tile'} />
          </div>
        </div>
        <KotohomesPageFooter />
      </Page>
    );
  }
}

KotohomesCalculatorPage.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default KotohomesCalculatorPage;
