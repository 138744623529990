import React, {useRef, useState} from 'react';

import styles from './KotohomesAdvertiseContactPage.module.css';
import image from './pexels-olga-divnaya-13234697.jpg';
import Page from '../common/Page';
import KotohomesPageFooter from '../KotohomesPageFooter';

const KotohomesAdvertiseContactPage = () => {
  const [inflight, setInflight] = useState(false);
  const [errors, setErrors] = useState();
  const dialogRef = useRef(null);
  const onsubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const data = {
      role: form.role.value,
      name: form.name.value,
      company: form.company.value,
      phone: form.phone.value,
      email: form.email.value,
      comments: form.comments.value,
    };
    setInflight(true);
    let newErrors = null;
    try {
      const resp = await fetch('/api/email/advertise', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data),
      });
      if (!resp.ok) {
        if (resp.status === 400) {
          newErrors = await resp.json();
        } else {
          newErrors = {other_errors: [`${resp.status} ${resp.statusText}`]};
        }
      }
    } catch (e) {
      newErrors = {
        other_errors: [e.toString()],
      };
    }
    if (newErrors) {
      setErrors(newErrors);
      setInflight(false);
      return;
    }
    if (errors) {
      setErrors(null);
    }
    dialogRef.current.showModal();
  };
  const hasValidationErrors =
    errors && Object.keys(errors).filter((v) => v !== 'other_errors').length > 0;
  return (
    <Page className={styles.root} title="Advertise">
      <div className={styles.basePage}>
        <div className={styles.content}>
          <form onSubmit={onsubmit}>
            {hasValidationErrors && (
              <div className={styles.validationErrors}>
                Please correct the errors below and try again
              </div>
            )}
            <fieldset>
              <legend>I am a:</legend>
              <label>
                <input
                  type="radio"
                  name="role"
                  value="realtor"
                  defaultChecked
                  disabled={inflight}
                />
                Realtor
              </label>
              <label>
                <input type="radio" name="role" value="lender" disabled={inflight} />
                Lender
              </label>
              <label>
                <input type="radio" name="role" value="other" disabled={inflight} />
                Other
              </label>
              <ValidationError messages={errors?.role} />
            </fieldset>
            <label>
              Name
              <input name="name" required disabled={inflight} />
              <ValidationError messages={errors?.name} />
            </label>
            <label>
              Company
              <input name="company" disabled={inflight} />
              <ValidationError messages={errors?.company} />
            </label>
            <label>
              Phone
              <input name="phone" required type="tel" disabled={inflight} />
              <ValidationError messages={errors?.phone} />
            </label>
            <label>
              Email Address
              <input name="email" required type="email" disabled={inflight} />
              <ValidationError messages={errors?.email} />
            </label>
            <label>
              Anything else we should know about you?
              <textarea rows="5" name="comments" disabled={inflight} />
              <ValidationError messages={errors?.comments} />
            </label>
            <ValidationError messages={errors?.non_field_errors} />
            {errors?.other_errors ? (
              <div className={styles.otherErrors}>
                <div>Something went wrong, please try again.</div>
                {errors.other_errors.map((message, idx) => (
                  <div key={idx}>{message}</div>
                ))}
              </div>
            ) : null}
            <input type="submit" value="Submit" disabled={inflight} />
          </form>
          <img className={styles.image} src={image} />
        </div>
      </div>
      <dialog
        ref={dialogRef}
        className={styles.successDialog}
        onClose={(e) => {
          window.location.href = '/';
        }}>
        <p>Thanks, we'll reach out to you shortly!</p>
        <form method="dialog">
          <div>
            <button>Continue</button>
          </div>
        </form>
      </dialog>
      <KotohomesPageFooter />
    </Page>
  );
};

const ValidationError = ({messages}) => {
  if (!messages) {
    return null;
  }
  return (
    <div className={styles.validationError}>
      {messages.map((message, idx) => (
        <div key={idx}>{message}</div>
      ))}
    </div>
  );
};

export default KotohomesAdvertiseContactPage;
